<template>
	<canvas id='carbonGaugeChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'

export default {
	name: 'CarbonGaugeChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		function generateProgress(totalBar, progressPos){
			const data = Array(totalBar).fill(1)
			let backgroundColor = Array(totalBar)
			backgroundColor = backgroundColor.fill('rgba(75, 192, 192, 0.8)', 0, progressPos)
			backgroundColor = backgroundColor.fill('rgba(0, 0, 0, 0.1)', progressPos, totalBar)
			return { data, backgroundColor }
		}

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('carbonGaugeChart').getContext('2d')

			const chart = new Chart(ctxChart, {
				type: 'doughnut',
				data: {
					datasets: [{
						label: 'dd',
						data: [],
						borderColor: 'rgba(255, 255, 255, 1)',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						cutout: '80%',
						circumference: 180,
						rotation: 270
					}]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
					},
					plugins: {
						legend: false,
						tooltip: {
							enabled: false
						}
					},
					
					scales: {
						x: {
							display: false,
						},
						y:{
							display: false,
						}
					}
				},
			})
			return chart
		}

		onMounted(() => {
			const { data, backgroundColor } = generateProgress(100, 80)
			chart = renderChart()
			chart.data.datasets[0].data = data
			chart.data.datasets[0].backgroundColor = backgroundColor
			chart.update()
		})
	}
}
</script>

<style lang='scss'>

</style>