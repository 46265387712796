<template>
	<canvas id='frontCongestionChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'

export default {
	name: 'FrontCongestionChart',
	setup(){
		const hours = [
				'12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
				'6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
				'12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
				'6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
				'12 AM',
			]

		let data1 = [
			10,8,5,5,7,18,27,38,78,71,45,38,
			35,48,47,41,36,61,79,51,45,28,15, 15, 12
		]

		const data2 = [
			15,11,5,5,7,34,45,60,90,63,55,41,
			45,56,59,51,38,71,92,83,40,31,10, 14, 11
		]

		function limitQueueData(data){
			const currentHour = dayjs().hour()
			for (let i = currentHour; i < data.length; i++) {
				data[i] = null
			}
			return data
		}

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('frontCongestionChart').getContext('2d')

			const chart = new Chart(ctxChart, {
				type: 'line',
				data: {
					labels: hours,
					datasets: [{
						label: 'Today',
						data: data1,
						backgroundColor: 'rgba(37,99,235, 0.4)',
						borderColor: 'rgba(255, 99, 132, 0.2))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 2,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					},{
						label: 'Average for Year 2021',
						data: data2,
						backgroundColor: 'rgba(153, 102, 255, 0.2)',
						borderColor: 'rgba(255, 99, 132, 0.2))',
						borderDash: [5, 5],
						fill: true,
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					},
				]
				},
				options: {
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
					},
					maintainAspectRatio: false,
					responsive: true,
					scales: {
						x: {
							ticks: {
								maxTicksLimit: 12
							}
						},
						y: {
							beginAtZero: true,
							suggestedMax: 100,
							
							ticks: {
								maxTicksLimit: 10,
								callback: (value, index, ticks) => {
									return value+'%';
								}
							}
						}
					},
					plugins: {
						legend: {
							// display: false
						},
						tooltip: {
							displayColors: true,
							callbacks: {
								label(context){
									const dataset = context.dataset.label
									const v = context.dataset.data[context.dataIndex]
									return `${dataset}: ${v}%`
								}
							}
						}
					}
				}
			});

			return chart
		}
	
		onMounted(() => {
			data1 = limitQueueData(data1)
			chart = renderChart()
		})
	}
}
</script>