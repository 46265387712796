<template>
	<canvas id='queueChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'

export default {
	name: 'QueueChart',
	components: {
		
	},
	setup(){

		let queueData = [2, 2, 5, 4, 8, 13, 24, 37, 60, 45, 41, 37, 
						24, 31, 29, 23, 31, 35, 38, 45, 34, 22, 23, 8, 7]
		const usualQueueData = [4, 4, 2, 3, 6, 15, 28, 40, 65, 48, 42, 35, 
						31, 36, 32, 26, 35, 38, 45, 54, 35, 24, 25, 10, 8]
		const hours = ['12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM',
					'9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 AM', '3 AM', '4 AM',
					'5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM', '12 AM']

		function limitQueueData(queueData){
			const currentHour = dayjs().hour()
			for (let i = currentHour; i < queueData.length; i++) {
				queueData[i] = null
			}
			return queueData
		}

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('queueChart').getContext('2d')

			const chart = new Chart(ctxChart, {
				type: 'line',
				data: {
					labels: hours,
					datasets: [{
						label: 'Queue Length',
						data: queueData,
						backgroundColor: 'rgba(37,99,235, 0.4)',
						borderColor: 'rgba(255, 99, 132, 0.2))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 2,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					},
					{	
						label: 'Usual Queue Length',
						data: usualQueueData,
						backgroundColor: 'rgba(153, 102, 255, 0.2)',
						borderColor: 'rgba(255, 99, 132, 0.2))',
						borderDash: [5, 5],
						fill: true,
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					},
					]
				},
				options: {
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
						},
					responsive: true,
					scales: {
						x: {
							ticks: {
								maxTicksLimit: 12
							}
						},
						y: {
							beginAtZero: true,
							grace: 10
						}
					}
				},
			})

			return chart
		}
		
		onMounted(() => {
			queueData = limitQueueData(queueData)
			chart = renderChart()
		})
	}
}
</script>

<style lang='scss'>

</style>