<template>
	<canvas id='averageSpeedChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'

export default {
	name: 'AverageSpeedChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('averageSpeedChart').getContext('2d')

			const tooFast = {
				id: 'tooFast',
				beforeDraw(chart, args, options){
					const { ctx, chartArea: { top, right, bottom, left, width, height },
						scales: { x, y }} = chart
					ctx.save()

					ctx.strokeStyle = 'red'
					ctx.setLineDash([10, 10])
					ctx.strokeRect(left, y.getPixelForValue(90), width, 0)
					ctx.restore()

					ctx.fillStyle = 'rgba(255, 99, 132, 0.2)'
					ctx.fillRect(left, top, width, y.getPixelForValue(90)-top)
					ctx.restore()

					ctx.font = '25px'
					ctx.fillStyle = 'red'
					ctx.fillText('Speed Limit', left+10, top+20)
				}
			}

			const healthySpeed = {
				id: 'healthySpeed',
				beforeDraw(chart, args, options){
					const { ctx, chartArea: { top, right, bottom, left, width, height },
						scales: { x, y }} = chart
					ctx.save()

					ctx.fillStyle = 'rgba(75, 192, 192, 0.2)'
					ctx.fillRect(left, y.getPixelForValue(90), width, y.getPixelForValue(40) - y.getPixelForValue(90))
					ctx.restore()

					ctx.font = '25px'
					ctx.fillStyle = 'rgba(3, 10, 43, 0.8)'
					ctx.fillText('Healthy Speed', left+10, y.getPixelForValue(90)+20)
				}
			}

			const tooSlow = {
				id: 'tooSlow',
				beforeDraw(chart, args, options){
					const { ctx, chartArea: { top, right, bottom, left, width, height },
						scales: { x, y }} = chart
					ctx.save()

					ctx.strokeStyle = 'red'
					ctx.setLineDash([10, 10])
					ctx.strokeRect(left, y.getPixelForValue(40), width, 0)
					ctx.restore()

					ctx.fillStyle = 'rgba(255, 99, 132, 0.2)'
					ctx.fillRect(left, y.getPixelForValue(40), width, y.getPixelForValue(0) - y.getPixelForValue(40))
					ctx.restore()

					ctx.font = '25px'
					ctx.fillStyle = 'red'
					ctx.fillText('Very Slow', left+10, y.getPixelForValue(40)+20)
				}
			}

			const chart = new Chart(ctxChart, {
				type: 'line',
				data: {
					// labels: hours,
					datasets: [{
						backgroundColor: 'rgba(37,99,235, 0.3)',
						borderColor: 'rgba(255, 99, 132, 0.2))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 2,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					},
					// {
					// 	backgroundColor: 'rgba(37,99,235, 0.1)',
					// 	borderColor: 'rgba(255, 99, 132, 0.2))',
					// 	borderDash: [5, 5],
					// 	fill: true,
					// 	borderWidth: 1,
					// 	barPercentage: 1.0,
					// 	categoryPercentage: 1.0,
					// 	pointRadius: 0,
					// 	lineTension: 0.1
					// },
				]
				},
				options: {
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
						},
					plugins: {
						legend: true,
						// title:{
						// 	display: true,
						// 	text: 'Vehicles Average Speed',
						// 	padding: 4
						// },
						tooltip: {
							displayColors: true,
							callbacks: {
								title(context){
									const datetime_obj = dayjs(context[0].raw.x)
									if (store.state.detailsData.is_daily_data){
										return datetime_obj.format('D MMM YYYY')
									} else {
										
										return datetime_obj.format('D MMM YYYY, h A')
									}
								},
								label(context){
									const dataset = context.dataset.label
									const v = context.dataset.data[context.dataIndex]
									return `${dataset}: ${v.y} km/h`
								}
							}
						}
					},
					responsive: true,
					scales: {
						x: {
							type: 'time',
							time: {
								unit: 'hour'
							},
							offset: true,
							title: {
								display: true,
								text: 'Time'
							},
							grid: {
								display: false,
							},
							ticks: {
								align: 'start',
								autoSkip: true,
								// labelOffset: 18,
								// maxTicksLimit: 9,
								// count: 7,
								font: {
									size: 12
								},
								callback: function(value, index, ticks){
									if (value.includes('AM') || value.includes('PM')){
										const result = [value.slice(0, -2), value.slice(-2)]
										return result[0] + ' ' + result[1]
									} else {
										// check if year, remove year from the value (May 2022 -> May)
										if (parseInt(value.split(' ')[1]) > 100) {
											return value.split(' ')[0]
										} else {
											return value
										}
									}		
								}
							}
						},
						y:{
							beginAtZero: true,
							suggestedMax: 110,
							title: {
								display: true,
								text: 'Speed (km/h)'
							},
							ticks: {
								beginAtZero: true,
								callback: function(value, index, ticks){
									return value
								}
							}
						}
					}
				},
				plugins: [tooFast, healthySpeed, tooSlow]
			})

			return chart
		}
		
		onMounted(() => {
			chart = renderChart()
		})

		store.watch((state, getters) => [state.detailsData],  () => {
			chart.data.datasets[0].data = store.state.detailsData.data.average_speed
			chart.data.datasets[0].label = store.state.timeframeLabel
			// if (store.state.detailsData.benchmark_exist){
			// 	let benchmarkAverageSpeed = store.state.detailsData.benchmark_data.average_speed
			// 	benchmarkAverageSpeed = benchmarkAverageSpeed.map(function(value, index){
			// 		const changedDate = store.state.detailsData.data.average_speed[index].x
			// 		return {
			// 			x: changedDate,
			// 			y: value.y
			// 		}
			// 	})
			// 	chart.data.datasets[1].data = benchmarkAverageSpeed
			// 	if (store.state.timeframeLabel == 'Today'){
			// 		chart.data.datasets[1].label = 'Yesterday'
			// 	} else if (store.state.timeframeLabel == 'This Week'){
			// 		chart.data.datasets[1].label = 'Last Week'
			// 	} else if (store.state.timeframeLabel == 'This Month'){
			// 		chart.data.datasets[1].label = 'Last Month'
			// 	} else if (store.state.timeframeLabel == 'This Year'){
			// 		chart.data.datasets[1].label = 'Last Year'
			// 	}
			// }
			
			if (store.state.detailsData.is_daily_data){
				if (store.state.detailsData.num_day > 120){
					chart.options.scales.x.time.unit = 'month'
					// set to no limit actually
					chart.options.scales.x.ticks.maxTicksLimit = 100
				} else if (store.state.detailsData.num_day > 25){
					chart.options.scales.x.time.unit = 'day'
					chart.options.scales.x.ticks.maxTicksLimit = 14
				} else {
					chart.options.scales.x.time.unit = 'day'
					chart.options.scales.x.ticks.maxTicksLimit = 9
				}
				
			} else {
				chart.options.scales.x.time.unit = 'hour'
				chart.options.scales.x.ticks.maxTicksLimit = 9
			}
			chart.update()
		})
	}
}
</script>

<style lang='scss'>

</style>