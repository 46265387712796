import { createApp, component } from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select';
import VueGoodTablePlugin from 'vue-good-table-next';
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import VueTippy from 'vue-tippy'
// import 'flowbite';

import './assets/tailwind.css'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import 'tippy.js/dist/tippy.css'



// import '../node_modules/flowbite/dist/flowbite.js'
import '../node_modules/flowbite/dist/datepicker'

if (process.env.VUE_APP_ENV == 'PRODUCTION'){
	axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
} else {
	axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

const app = createApp(App)
app.component('v-select', vSelect)
	.use(VueGoodTablePlugin)
	.use(VueTippy,{
		defaultProps: {
			placement: 'bottom',
			allowHTML: true,
		}
	})
	.use(store)
	.use(router)
	.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAP_API,
	}})
	.mount('#app')
