<template>
	<div class='w-screen min-h-screen h-fit bg-slate-100 font-["Inter"] pt-6 px-4 pb-16 text-slate-800 relative'>
		<div>
			<Header class="max-w-screen-2xl mx-auto pr-4"></Header>
		</div>
		<!-- max-w-screen-2xl mx-auto pr-4 -->
		<div class='max-w-screen-2xl  mx-auto'>
			<router-view @close='closeShowToast'></router-view>
		</div>
		<ToastNotification class='z-40' :showToast='showToast' @close='closeShowToast'/>
	</div>
	<CustomFooter/>
</template>

<script>
import { ref, onMounted } from 'vue';

import Header from './components/Header.vue';
import CustomFooter from '@/components/Footer.vue';
import ToastNotification from './components/ToastNotification.vue';

export default {
	components: {
		CustomFooter,
		Header,
		ToastNotification
	},
	setup(){
		const showToast = ref(false)

		function toggleShowToast(){
			showToast.value = !showToast.value
		}

		function closeShowToast(){
			showToast.value = false
		}

		onMounted(() => {
			setInterval(() =>{
				showToast.value = true
			}, 30000)
		})

		return { showToast, toggleShowToast, closeShowToast }
	}
}
</script>

<style src='../node_modules/vue-select/dist/vue-select.css'></style>
<style>
	body::-webkit-scrollbar {
	display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	body {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	.dropdown button{
		@apply w-full text-slate-800 text-base bg-white mr-0 font-medium rounded-sm pr-4 py-4 inline-flex items-center focus:ring-0 hover:bg-white
		/* text-white  focus:ring-4 font-medium rounded-lg dark:bg-blue-600 focus:outline-none dark:focus:ring-blue-800 hover:bg-blue-800 dark:hover:bg-blue-700 px-4 py-2 inline-flex items-center text-white bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg dark:bg-blue-600 focus:outline-none dark:focus:ring-blue-800 hover:bg-blue-800 dark:hover:bg-blue-700 text-sm px-4 py-2 inline-flex items-center */
	}

	.dropdown div:first-child {
		@apply ml-auto
	}

	.dropdown div:nth-child(2) {
		@apply absolute right-0
	}
</style>


