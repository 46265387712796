<template>
	<div>
		<RouteDropdown/>
	</div>
	<div class="mt-4 grid grid-cols-3 overflow-clip gap-2">
		<div class='col-span-3 xl:col-span-2 bg-transparent min-h-screen rounded-sm '>
			<TimeFrameButtons class='mb-2'/>
			<div class="bg-white ">
				<div v-if='bulletData!=null' class=''>
					<RouteScore :isDetailsPage='true' :bulletData='bulletData'/>
				</div>
				<div v-else class='h-48 w-full'>
				</div>
			</div>
			<hr/><hr/>
			<div class='grid grid-cols-1'>
				<TrafficCongestion/>
				<hr class=""><hr/>
				<div class="bg-white rounded-sm">
					<div class="pt-4 pl-4">
						<div class="flex justify-between items-center pb-4">
							<span class=" font-bold text-2xl">Traffic Speed</span>
						</div>
						<div class="grid grid-cols-4 gap-x-4 mt-4 h-20 ">
							<div class="flex flex-col justify-between">
								<span class="font-extralight">Average Speed (Past Hour)</span>
								<span class="font-bold text-2xl">{{ bulletData!=null ? bulletData.average_speed[1]:0 }} KM/H</span>
							</div>
							<div class="flex flex-col justify-between">
								<span class="font-extralight">Maximum Speed (Past Hour)</span>
								<span class="font-bold text-2xl">{{ bulletData!=null ? bulletData.max_average_speed[1]:0 }} KM/H</span>
							</div>
						</div>
					</div>
					<div class="flex flex-col">
						<div class='grid grid-cols-5 gap-x-4'>
							<div class='col-span-5 bg-white p-4 h-full rounded-sm flex flex-col'>
								<div class='flex-grow py-auto'>
									<AverageSpeedChart class='h-[30rem]'/>
								</div>
								<WarningBox status='success' text='Vehicles average speed throughout the day are within speed limit.'></WarningBox>
							</div>
						</div>
					</div>	
				</div>
				<hr class=""><hr/>
				<div class="bg-white rounded-sm pb-4  px-4">
					<div class="py-4">
						<div class="flex justify-between items-center pb-4">
							<span class="font-bold text-2xl">Vehicles Type</span>
						</div>
						<div class="grid grid-cols-4 gap-x-4 mt-4 h-16 ">
							<div class="flex flex-col justify-between">
								<span class="font-extralight">Total Vehicles (Past Hour)</span>
								<span class="font-bold text-2xl">{{ bulletData!=null ? bulletData.vehicle_count[1]:0 }}</span>
							</div>
							<div class="flex flex-col justify-between">
								<span class="font-extralight">Total Light Vehicles (Past Hour)</span>
								<span class="font-bold text-2xl">{{ bulletData!=null ? bulletData.light_vehicle_count[1]:0 }}
									({{bulletData!=null ? Math.round((bulletData.light_vehicle_count[1]/bulletData.vehicle_count[1])*100*100)/100:0}}%)</span>
							</div>
							<div class="flex flex-col justify-between">
								<span class="font-extralight">Total Heavy Vehicles (Past Hour)</span>
								<span class="font-bold text-2xl">{{ bulletData!=null ? bulletData.heavy_vehicle_count[1]:0 }}
									({{bulletData!=null ? Math.round((bulletData.heavy_vehicle_count[1]/bulletData.vehicle_count[1])*100*100)/100:0}}%)</span>
							</div>
						</div>
					</div>
					<div class=' bg-white rounded-sm mt-4'>
						<VehicleTypeManyChart class="h-[24rem]"/>
					</div>
				</div>
				<hr class=""><hr/>
				<div class="bg-white rounded-sm pb-4  px-4">
					<div class="py-4">
						<div class="flex justify-between items-center pb-4">
							<span class=" font-bold text-2xl">Travel Time</span>
						</div>
						<div class="grid grid-cols-4 gap-x-4 mt-4 h-16 ">
							<div class="flex flex-col justify-between">
								<span class="font-extralight">Average Travel Time (Past Hour)</span>
								<span class="font-bold text-2xl">{{ bulletData!=null ? bulletData.average_travel_time[1]:0 }} Minutes</span>
							</div>
							<div class="flex flex-col justify-between">
								<span class="font-extralight">Delay (Past Hour)</span>
								<span class="font-bold text-2xl">{{ 3 }} Minutes</span>
							</div>
						</div>
					</div>
					<div class=' bg-white rounded-sm'>
						<TravelTimeChart class='h-[30rem]'/>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-grow w-full hidden xl:inline col-span-0 xl:col-span-1 rounded-sm">
			<Map :isDetailsPage='true' :path="store.state.routeList.find(element => element.id == store.state.routeId).path" class='inline-block sticky top-0 h-screen w-full'></Map>
		</div>
	</div>

	
</template>

<script>
import { onMounted, ref, watchEffect, watch } from 'vue';
import { useStore } from 'vuex'
import axios from 'axios';
import dayjs from 'dayjs'
import 'chartjs-adapter-dayjs-3';

import AverageSpeedChart from '@/charts/AverageSpeedChart.vue';
import OverspeedingChart from '@/charts/OverspeedingChart.vue';
import CongestionLevelChart from '@/charts/CongestionLevelChart.vue';
import VehicleCountChart from '@/charts/VehicleCountChart.vue';
import CarbonChart from '@/charts/CarbonChart.vue';
import TravelTimeChart from '@/charts/TravelTimeChart.vue';
import GlobalChart from '@/charts/GlobalChart.vue';
import VehicleTypeChart from '@/charts/VehicleTypeChart.vue';
import VehicleCountChartBig from '@/charts/VehicleCountChartBig.vue';
import VehicleTypeManyChart from '@/charts/VehicleTypeManyChart.vue';

import CongestionChartLegend from '@/charts/CongestionChartLegend.vue'
import VehicleCountLegend from '@/charts/VehicleCountLegend.vue'

import Score from '@/components/Score.vue'
import RouteScore from '@/components/RouteScore.vue';
import RouteDropdown from '@/components/RouteDropdown.vue'
import TimeFrameButtons from '@/components/TimeFrameButtons.vue'
import WarningBox from '@/components/WarningBox.vue';
import Map from '@/components/Map.vue';
import TrafficCongestion from '@/components/TrafficCongestion.vue';


export default {
	name: 'DetailsPage',
	components: {
		AverageSpeedChart,
		OverspeedingChart,
		CongestionLevelChart,
		VehicleCountChart,
		CarbonChart,
		TravelTimeChart,
		GlobalChart,
		VehicleTypeChart,
		VehicleCountChartBig,
		VehicleTypeManyChart,

		CongestionChartLegend,
		VehicleCountLegend,

		Score,
		RouteScore,
		RouteDropdown,
		TimeFrameButtons,
		WarningBox,
		Map,
		TrafficCongestion
	},
	setup(){
		const store = useStore()
		const bulletData = ref()

		function getPad(){
			let pad = null
			if (store.state.rangeActive[0]){
				pad = 'today'
			} else if (store.state.rangeActive[1]) {
				pad = 'this_week'
			} else if (store.state.rangeActive[2]) {
				pad = 'this_month'
			} else if (store.state.rangeActive[3]) {
				pad = 'this_year'
			}

			return pad
		}

		const getRouteDetails = async (routeId, startDate, endDate, pad) => {
			axios
				.get(`/route/${routeId}`, {
					params: {
						start_date: startDate,
						end_date: endDate,
						pad: pad
					}})
				.then(response => {
					store.commit('setDetailsData', response.data)
					const startDateObj = dayjs(startDate)
					const endDateObj = dayjs(endDate)
					let label = ''
					if (store.state.rangeActive[0]){
						label = 'Today'
					} else if (store.state.rangeActive[1]) {
						label = 'This Week'
					} else if (store.state.rangeActive[2]) {
						label = 'This Month'
					} else if (store.state.rangeActive[3]) {
						label = 'This Year'
					} else{
						if (startDateObj.format('D/M/YYYY') == endDateObj.format('D/M/YYYY')){
							label = `${startDateObj.format('D/M/YYYY')}`
							// label = ''
						} else {
							// label = ''
							label = `${startDateObj.format('D/M/YYYY')} - ${endDateObj.format('D/M/YYYY')}`
						}
						
					}
					store.commit('setTimeframeLabel', label)
				})
				.catch(error => {
					console.log(error)
				}
			)
		}

		const getRouteCongestionDetails = async (routeId, startDate, endDate) => {
			axios
				.get(`/congestion/route/${routeId}`, {
					params: {
						start_date: startDate,
						end_date: endDate,
					}})
				.then(response => {
					store.commit('setMatrixDetailsData', response.data)
				})
				.catch(error => {
					console.log(error)
				}
			)
		}

		const getRouteBulletData = async (routeId) => {
			axios
				.get(`/last-hour/route/${routeId}`, {
					// supposedly no need to supply date cuz backend will automatically take today
					// but today doesnt have nice data so we use data from july
					params: {
						date: '2022-07-15'
					}})
				.then(response => {
					bulletData.value = response.data.data
				})
				.catch(error => {
					console.log(error)
				}
			)
		}
		
		

		onMounted(() => {
			getRouteDetails(store.state.routeId, store.state.startDate, store.state.endDate, getPad())
			getRouteCongestionDetails(store.state.routeId, store.state.startDate, store.state.endDate)
			getRouteBulletData(store.state.routeId)
		})

		store.watch((state, getters) => [state.routeId, state.startDate, state.endDate],  () => {
			if (dayjs(store.state.startDate) <= dayjs(store.state.endDate)){
				getRouteDetails(store.state.routeId, store.state.startDate, store.state.endDate, getPad())
				getRouteCongestionDetails(store.state.routeId, store.state.startDate, store.state.endDate)
			}
		})

		store.watch((state, getters) => [state.routeId],  () => {
			getRouteBulletData(store.state.routeId)
		})

		return { bulletData, store }
	}
}
</script>
