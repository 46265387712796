<template>
	<div class='flex flex-col relative'>
		<span class='font-bold text-2xl mt-8'>Vehicles Detected</span>
		<div class='w-full bg-transparent  rounded-sm'>
			<div class='grid grid-cols-3 flex flex-col gap-x-2 mt-4'>
				<div class='col-span-3 xl:col-span-2'>
					<div class='h-[50rem] bg-white bg-opacity-50 px-4 py-4'>
						<VueGoodTable
							class=''
							:columns='columns'
							:rows='rows'
							theme='polar-bear'
							:search-options="{
								enabled: true,
								trigger: '',
								skipDiacritics: true,
								placeholder: 'Search by Plate Number or Date',
								searchFn: scopeSearch
							}"
							:pagination-options="{
								enabled: true,
								mode: 'records',
								perPage: 8,
								position: 'bottom',
								perPageDropdownEnabled: false,
								perPageDropdown: [10, 20],
								dropdownAllowAll: false,
								setCurrentPage: 1,
								nextLabel: 'Next',
								prevLabel: 'Previous',
								rowsPerPageLabel: 'Rows per page',
								ofLabel: 'of',
								pageLabel: 'page',
								allLabel: 'All',		
						}"/>
					</div>
				</div>
				<div class='hidden xl:inline col-span-0 xl:col-span-1 grow rounded-sm '>
					<Map class='' :isDetectedVehiclePage="true"></Map>
				</div>
				<!-- <div class='pl-4 grow hidden xl:inline rounded-sm mt-4 '>
					<img v-if='mapUrl!=""' class='object-cover h-full w-full' :src='mapUrl' alt='instruments'/>
					<img src='../assets/cam-map2.png'  class='object-cover h-full w-full' alt='subang cctv map'>
				</div> -->
			</div>
			
		</div>
	</div>
</template>

<script>
import { ref, onMounted, defineEmits, defineComponent  } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import axios from 'axios';
import dayjs from 'dayjs';

import Map from '@/components/Map.vue'

export default defineComponent({
	name: 'VehicleDetectedPage',
	components: {
		VueGoodTable,
		Map
	},
	setup(props, {emit}){
		// const emit = defineEmits(['close'])

		const columns = ref([
		{
			label: 'Plate Number',
			field: 'plateNumber',
		},
		{
			label: 'Date and Time Detected',
			field: 'dateTimeDetected',
			formatFn: function timeToString(value){
				return dayjs(value).format('DD/M/YYYY h:mm A')
			}
		},
		{
			label: 'Location Detected',
			field: 'locationDetected',
			filterOptions: {
				styleClass: '', // class to be added to the parent th element
				enabled: true, // enable filter for this column
				placeholder: 'Filter Location Detected', // placeholder for filter input
				filterValue: '', // initial populated value for this filter
				filterDropdownItems: ['Camera 1', 'Camera 2', 'Camera 3',
					'Camera 4', 'Camera 5', 'Camera 6', 'Camera 7', 'Camera 8',
				], // dropdown (with selected values) instead of text input
				// filterFn: this.columnFilterFn, //custom filter function that
				trigger: 'enter', //only trigger on enter not on keyup 
			},
		},
		{
			label: 'Remarks',
			field: 'remarks',
			filterOptions: {
				styleClass: '', // class to be added to the parent th element
				enabled: true, // enable filter for this column
				placeholder: 'Filter Remarks', // placeholder for filter input
				filterValue: '', // initial populated value for this filter
				filterDropdownItems: ['Stolen Vehicle'], // dropdown (with selected values) instead of text input
				// filterFn: this.columnFilterFn, //custom filter function that
				trigger: 'enter', //only trigger on enter not on keyup 
			},
		},
	],)
	// .subtract(7, 'day')
		const rows = ref([
			{ id:2, plateNumber:'WYU 9283', dateTimeDetected: dayjs('27/9/2022 09:13', 'DD/M/YYYY HH:mm'),locationDetected: 'Camera 1', remarks: 'Stolen Vehicle' },
			{ id:3, plateNumber:'FYG 9633', dateTimeDetected: dayjs('27/9/2022 10:27', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 3', remarks: 'Stolen Vehicle'  },
			{ id:4, plateNumber:'YJU 9090', dateTimeDetected: dayjs('27/9/2022 09:01', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 4', remarks: 'Stolen Vehicle'  },
			{ id:5, plateNumber:'BJY 3627', dateTimeDetected: dayjs('27/9/2022 09:13', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 5', remarks: 'Stolen Vehicle'},
			{ id:6, plateNumber:'NBL 1827', dateTimeDetected: dayjs('27/9/2022 11:52', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 7', remarks: 'Stolen Vehicle'  },
			{ id:7, plateNumber:'HMZ 2984', dateTimeDetected: dayjs('27/9/2022 16:32', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 1', remarks: 'Stolen Vehicle'  },
			{ id:8, plateNumber:'PAD 1212', dateTimeDetected: dayjs('26/9/2022 02:11', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 7', remarks: 'Stolen Vehicle'  },
			{ id:9, plateNumber:'PQO 1022', dateTimeDetected: dayjs('26/9/2022 12:09', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 1', remarks: 'Stolen Vehicle'  },
			{ id:10, plateNumber:'BJY 3627', dateTimeDetected: dayjs('26/9/2022 19:43', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 3', remarks: 'Stolen Vehicle'},
			{ id:11, plateNumber:'NBL 1827', dateTimeDetected: dayjs('26/9/2022 14:19', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 3', remarks: 'Stolen Vehicle'  },
			{ id:12, plateNumber:'HMZ 2984', dateTimeDetected: dayjs('26/9/2022 04:10', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 5', remarks: 'Stolen Vehicle'  },
			{ id:13, plateNumber:'PAD 1212', dateTimeDetected: dayjs('26/9/2022 09:13', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 1', remarks: 'Stolen Vehicle'  },
			{ id:14, plateNumber:'PQO 1022', dateTimeDetected: dayjs('26/9/2022 23:53', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 2', remarks: 'Stolen Vehicle'  },
			{ id:2, plateNumber:'FGY 4325', dateTimeDetected: dayjs('25/9/2022 09:13', 'DD/M/YYYY HH:mm'),locationDetected: 'Camera 1', remarks: 'Stolen Vehicle' },
			{ id:3, plateNumber:'BHY 1147', dateTimeDetected: dayjs('25/9/2022 10:27', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 3', remarks: 'Stolen Vehicle'  },
			{ id:4, plateNumber:'YJU 6723', dateTimeDetected: dayjs('25/9/2022 09:01', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 4', remarks: 'Stolen Vehicle'  },
			{ id:5, plateNumber:'AWF 0633', dateTimeDetected: dayjs('25/9/2022 09:13', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 5', remarks: 'Stolen Vehicle'},
			{ id:6, plateNumber:'NMY 1243', dateTimeDetected: dayjs('25/9/2022 11:52', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 7', remarks: 'Stolen Vehicle'  },
			{ id:7, plateNumber:'QDT 0990', dateTimeDetected: dayjs('25/9/2022 16:32', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 1', remarks: 'Stolen Vehicle'  },
			{ id:8, plateNumber:'PAD 2356', dateTimeDetected: dayjs('23/9/2022 02:11', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 7', remarks: 'Stolen Vehicle'  },
			{ id:9, plateNumber:'CSG 6312', dateTimeDetected: dayjs('24/9/2022 12:09', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 1', remarks: 'Stolen Vehicle'  },
			{ id:10, plateNumber:'OJT 4234', dateTimeDetected: dayjs('24/9/2022 19:43', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 3', remarks: 'Stolen Vehicle'},
			{ id:11, plateNumber:'VDT 7645', dateTimeDetected: dayjs('24/9/2022 14:19', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 3', remarks: 'Stolen Vehicle'  },
			{ id:12, plateNumber:'ADT 1234', dateTimeDetected: dayjs('24/9/2022 04:10', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 5', remarks: 'Stolen Vehicle'  },
			{ id:13, plateNumber:'VSA 3311', dateTimeDetected: dayjs('24/9/2022 09:13', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 1', remarks: 'Stolen Vehicle'  },
			{ id:14, plateNumber:'MFS 2354', dateTimeDetected: dayjs('24/9/2022 23:53', 'DD/M/YYYY HH:mm'), locationDetected: 'Camera 2', remarks: 'Stolen Vehicle'  },
		],)

		function scopeSearch(row, col, cellValue, searchTerm){
			if (searchTerm.slice(0, 5)=='date:' && col.field == 'dateTimeDetected'){
				return cellValue.slice(0, 10).toLowerCase().includes(searchTerm.slice(6).replace(' ', ''))
			} else if (col.field == 'plateNumber'){
				return cellValue.toLowerCase().includes(searchTerm)
			}
		}

		// only use to create the static image. after that, download and reuse the same image
		const mapUrl = ref('')
		// function getMapImage() {
		// 	var params = new URLSearchParams()
		// 	params.append('key', '')
		// 	params.append('size', '800x1000')
		// 	params.append('zoom', '15')
		// 	params.append('center', '3.0566825088366187, 101.58483221970356')
		// 	params.append('map_id', '9c27a2b9ec7422a2')
		// 	params.append('markers', 'color:blue|label:1|3.0569471224030895, 101.59183143448483')
		// 	params.append('markers', 'color:blue|label:2|3.0505350321679563, 101.5829318649444')
		// 	params.append('markers', 'color:blue|label:3|3.0574560167531444, 101.57823263501287')
		// 	params.append('markers', 'color:blue|label:4|3.063841281978014, 101.58623634614374')
		// 	params.append('markers', 'color:blue|label:5|3.046699544774097, 101.58898292800235')
		// 	params.append('markers', 'color:blue|label:6|3.058227593186204, 101.58512054723671')
		// 	params.append('markers', 'color:blue|label:7|3.06246995296762, 101.59284530876799')
		// 	params.append('markers', 'color:blue|label:8|3.0678266964500702, 101.58022819826688')

		// 	const config = {
		// 		responseType: 'blob',
		// 		params: params
		// 	}

		// 	axios.get('https://maps.googleapis.com/maps/api/staticmap?', config)
		// 		.then(response => {
		// 			const urlCreator = window.URL || window.webkitURL
		// 			console.log(window.webkitURL.createObjectURL(response.data))
		// 			mapUrl.value = window.webkitURL.createObjectURL(response.data)
		// 		}
		// 	)
		// }

		onMounted(() => {
			// getMapImage()
			emit('close')
		})
		

		return { columns, rows, scopeSearch, mapUrl }
	}
})
</script>