<template>
	<div class="mt-8 grid grid-cols-3 gap-2">
		<div class="bg-transparent col-span-2 flex flex-col">
			<div class="w-full mt-28">
				<iframe class="w-full aspect-video" src="https://www.youtube.com/embed/YI8GtR2VewY?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1" 
					frameborder="0" 
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
					allowfullscreen>
				</iframe>
			</div>
			<div class="bg-white grow">
			</div>
		</div>
		<div class="">
			<div class="flex flex-col h-full items-end">
				<input v-model='date' :max='dayjs().format("YYYY-MM-DD")' class='border border-transparent py-3 px-4' type='date' name='date' id='date'>
				<div class="rounded-sm text-end flex mt-2 w-2/3 bg-white px-2">
					<dropdown placement="bottom" :text="'Persiaran Kewajipan&nbsp;&nbsp;'" class="dropdown simulation-dropdown w-full">
						<ul class="ml-auto py-1 text-sm text-gray-700">
							<li :value='route.name' :key='route' v-for='route in routeList'>
								<a class="block py-2 px-4 hover:bg-gray-100">{{ route.name }}</a>
							</li>
						</ul>
					</dropdown>
				</div>
				<div class="bg-white grow mt-2 w-full h-full grid grid-rows-3 gap-y-4 rounded-sm">
					<div class="p-4 pr-8 flex flex-col">
						<span class="font-light text-xl">Congestion Level</span>
						<div class="max-w-full grow mt-2">
							<CongestionLevelTimerVSAIChart class="max-w-full"/>
						</div>
					</div>
					<div class="px-4 pr-8 flex flex-col">
						<span class="font-light text-xl">Time Saved</span>
						<div class="grow mt-2">
							<TimeSavedChart class=""/>
						</div>
					</div>
					<div class="px-4 pr-8 flex flex-col">
						<span class="font-light text-xl">Traffic Jam Length</span>
						<div class="grow mt-2">
							<TrafficJamLengthChart class=""/>
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		
		<!-- <iframe width="560" height="315" 
			src="https://www.youtube.com/embed/BCrGbYZLkEY?autoplay=1&loop=1" 
			frameborder="0" 
			allowfullscreen>
		</iframe> -->
	</div>
</template>

<script>
import { onMounted, ref, watchEffect, watch } from 'vue';
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { Dropdown } from 'flowbite-vue'
import CongestionLevelTimerVSAIChart from '@/charts/CongestionLevelTimerVSAIChart.vue';
import TimeSavedChart from '@/charts/TimeSavedChart.vue';
import TrafficJamLengthChart from '@/charts/TrafficJamLengthChart.vue';

export default {
	name: 'SimulationPage',
	props: [''],
	components: {
		Dropdown,
		CongestionLevelTimerVSAIChart,
		TimeSavedChart,
		TrafficJamLengthChart
	},
	setup(){
		const store = useStore()
		const routeList = store.state.routeList
		const date = ref(dayjs().format("YYYY-MM-DD"))

		return { dayjs, date, routeList }
	}
}
</script>

<style>
.simulation-dropdown button{
	@apply py-3 text-base
}
</style>