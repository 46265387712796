<template>
	<canvas id='stateOriginMapChart' class='w-full h-full'></canvas>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';
import axios from 'axios';
import * as ChartGeo from 'chartjs-chart-geo';
import { ChoroplethController, GeoFeature, ColorScale, ProjectionScale } from 'chartjs-chart-geo';
Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale)

import mys from '@/assets/mys.json';
import mysTopo from '@/assets/mys.topo.json';


export default {
	name: 'StateOriginMapChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		const getMapTopoJson = () => {
			return axios
				.get(`https://raw.githubusercontent.com/markmarkoh/datamaps/master/src/js/data/mys.topo.json`)
				// .get(`https://unpkg.com/us-atlas/states-10m.json`)
				.then(response => {
					const mys = response.data
					let nation = ChartGeo.topojson.feature(mys, mys.objects.mys).features[0]
					let states = ChartGeo.topojson.feature(mys, mys.objects.mys).features

					// remove sabah and sarawak outline
					let coordinates = nation.geometry.coordinates
					coordinates = coordinates.filter((d)=>{
						return d[0][0][0] < 117
					})
					nation.geometry.coordinates = coordinates

					// remove sabah and sarawak region
					states = states.filter((d)=>{
						return !(d.properties.name == 'Sarawak' || d.properties.name == 'Sabah'
						|| d.properties.name == 'Labuan' || d.properties.name == null)
					})

					return { nation, states }
				})
				.catch(error => {
					console.log(error)
				}
			)
		}

		const data = [
			{state: 'Selangor', value: 700},
			{state: 'Kuala Lumpur', value: 700},
			{state: 'Putrajaya', value: 700},
			{state: 'Perak', value: 250},
			{state: 'Kelantan', value: 150},
			{state: 'Trengganu', value: 250},
			{state: 'Johor', value: 200},
			{state: 'Pahang', value: 350},
			{state: 'Perlis', value: 100},
			{state: 'Negeri Sembilan', value: 200},
			{state: 'Melaka', value: 100},
			{state: 'Pulau Pinang', value: 0},
		]

		function mapDataToMap(data, states){
			let mappedData = []
			for (const d of data) {
				mappedData.push({
					value: d.value,
					feature: states[states.findIndex(x => x.properties.name === d.state)]
				})
			}
			return mappedData
		}

		

		let chart = null
		async function renderChart(){
			const { nation, states } = await getMapTopoJson()
			const mappedData = mapDataToMap(data, states)

			const ctxChart = document.getElementById('stateOriginMapChart').getContext('2d');
			const chart = new Chart(ctxChart, {
				type: 'choropleth',
				data: {
					labels: mappedData.map((d) => d.feature.properties.name),
					datasets: [{
						outline: nation,
						data: mappedData,
						// backgroundColor(context){	
						// 	console.log(context)
						// 	if (context.mode == null){
						// 		return 'white'
						// 	} else {
						// 		const value = context.dataset.data[context.datasetIndex].value
						// 		const alpha = value/700
						// 		return `rgba(153, 102, 255, ${alpha})`
						// 	}
						// }
					},
					
				]
				},
				options: {
					maintainAspectRatio: false,
					responsive: true,
					plugins: {
						legend: {
							display: false
						},
					},
					scales: {
						xy: {
							projection: 'equalEarth'  
						},
						color: {
							quantize: 20,
							legend: {
								display: false,
								position: 'bottom',
								align: 'bottom'
							},
						}
					},
				},
			});
			return chart	
		}
		onMounted(() => {
			chart = renderChart()
		})
	}
}
</script>

<style lang='scss'>

</style>