<template>
	<div class='h-[46rem]'>
		<canvas id='vehicleCountChartBig' class='h-full'></canvas>
	</div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex'
import Chart from 'chart.js/auto';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import dayjs from 'dayjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(MatrixController, MatrixElement);
export default {
	name: 'VehicleCountChartBig',
	props: ['isVehicleCount', 'showPercentage', 'isSouthBound'],
	components: {
		
	},
	setup(props){
		const store = useStore()

		const daysOfWeek = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		]
		const hours = [
				'12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
				'6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
				'12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
				'6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
				// '12 AM',
			]

		// generate dummy data
		const northVehicleCountData = [
		{
			"x": 0,
			"y": 0,
			"v": 6
		},
		{
			"x": 0,
			"y": 1,
			"v": 11
		},
		{
			"x": 0,
			"y": 2,
			"v": 1
		},
		{
			"x": 0,
			"y": 3,
			"v": 0
		},
		{
			"x": 0,
			"y": 4,
			"v": 0
		},
		{
			"x": 0,
			"y": 5,
			"v": 0
		},
		{
			"x": 0,
			"y": 6,
			"v": 6
		},
		{
			"x": 0,
			"y": 7,
			"v": 4
		},
		{
			"x": 0,
			"y": 8,
			"v": 26
		},
		{
			"x": 0,
			"y": 9,
			"v": 73
		},
		{
			"x": 0,
			"y": 10,
			"v": 61
		},
		{
			"x": 0,
			"y": 11,
			"v": 98
		},
		{
			"x": 0,
			"y": 12,
			"v": 113
		},
		{
			"x": 0,
			"y": 13,
			"v": 124
		},
		{
			"x": 0,
			"y": 14,
			"v": 119
		},
		{
			"x": 0,
			"y": 15,
			"v": 100
		},
		{
			"x": 0,
			"y": 16,
			"v": 101
		},
		{
			"x": 0,
			"y": 17,
			"v": 114
		},
		{
			"x": 0,
			"y": 18,
			"v": 71
		},
		{
			"x": 0,
			"y": 19,
			"v": 52
		},
		{
			"x": 0,
			"y": 20,
			"v": 31
		},
		{
			"x": 0,
			"y": 21,
			"v": 19
		},
		{
			"x": 0,
			"y": 22,
			"v": 20
		},
		{
			"x": 0,
			"y": 23,
			"v": 7
		},
		{
			"x": 1,
			"y": 0,
			"v": 4
		},
		{
			"x": 1,
			"y": 1,
			"v": 0
		},
		{
			"x": 1,
			"y": 2,
			"v": 0
		},
		{
			"x": 1,
			"y": 3,
			"v": 2
		},
		{
			"x": 1,
			"y": 4,
			"v": 2
		},
		{
			"x": 1,
			"y": 5,
			"v": 0
		},
		{
			"x": 1,
			"y": 6,
			"v": 6
		},
		{
			"x": 1,
			"y": 7,
			"v": 48
		},
		{
			"x": 1,
			"y": 8,
			"v": 59
		},
		{
			"x": 1,
			"y": 9,
			"v": 93
		},
		{
			"x": 1,
			"y": 10,
			"v": 89
		},
		{
			"x": 1,
			"y": 11,
			"v": 133
		},
		{
			"x": 1,
			"y": 12,
			"v": 114
		},
		{
			"x": 1,
			"y": 13,
			"v": 99
		},
		{
			"x": 1,
			"y": 14,
			"v": 116
		},
		{
			"x": 1,
			"y": 15,
			"v": 125
		},
		{
			"x": 1,
			"y": 16,
			"v": 140
		},
		{
			"x": 1,
			"y": 17,
			"v": 150
		},
		{
			"x": 1,
			"y": 18,
			"v": 138
		},
		{
			"x": 1,
			"y": 19,
			"v": 100
		},
		{
			"x": 1,
			"y": 20,
			"v": 24
		},
		{
			"x": 1,
			"y": 21,
			"v": 13
		},
		{
			"x": 1,
			"y": 22,
			"v": 5
		},
		{
			"x": 1,
			"y": 23,
			"v": 10
		},
		{
			"x": 2,
			"y": 0,
			"v": 2
		},
		{
			"x": 2,
			"y": 1,
			"v": 2
		},
		{
			"x": 2,
			"y": 2,
			"v": 2
		},
		{
			"x": 2,
			"y": 3,
			"v": 0
		},
		{
			"x": 2,
			"y": 4,
			"v": 2
		},
		{
			"x": 2,
			"y": 5,
			"v": 0
		},
		{
			"x": 2,
			"y": 6,
			"v": 9
		},
		{
			"x": 2,
			"y": 7,
			"v": 41
		},
		{
			"x": 2,
			"y": 8,
			"v": 41
		},
		{
			"x": 2,
			"y": 9,
			"v": 86
		},
		{
			"x": 2,
			"y": 10,
			"v": 106
		},
		{
			"x": 2,
			"y": 11,
			"v": 107
		},
		{
			"x": 2,
			"y": 12,
			"v": 118
		},
		{
			"x": 2,
			"y": 13,
			"v": 93
		},
		{
			"x": 2,
			"y": 14,
			"v": 152
		},
		{
			"x": 2,
			"y": 15,
			"v": 122
		},
		{
			"x": 2,
			"y": 16,
			"v": 127
		},
		{
			"x": 2,
			"y": 17,
			"v": 145
		},
		{
			"x": 2,
			"y": 18,
			"v": 128
		},
		{
			"x": 2,
			"y": 19,
			"v": 102
		},
		{
			"x": 2,
			"y": 20,
			"v": 20
		},
		{
			"x": 2,
			"y": 21,
			"v": 9
		},
		{
			"x": 2,
			"y": 22,
			"v": 36
		},
		{
			"x": 2,
			"y": 23,
			"v": 2
		},
		{
			"x": 3,
			"y": 0,
			"v": 6
		},
		{
			"x": 3,
			"y": 1,
			"v": 0
		},
		{
			"x": 3,
			"y": 2,
			"v": 2
		},
		{
			"x": 3,
			"y": 3,
			"v": 0
		},
		{
			"x": 3,
			"y": 4,
			"v": 0
		},
		{
			"x": 3,
			"y": 5,
			"v": 0
		},
		{
			"x": 3,
			"y": 6,
			"v": 7
		},
		{
			"x": 3,
			"y": 7,
			"v": 47
		},
		{
			"x": 3,
			"y": 8,
			"v": 48
		},
		{
			"x": 3,
			"y": 9,
			"v": 86
		},
		{
			"x": 3,
			"y": 10,
			"v": 113
		},
		{
			"x": 3,
			"y": 11,
			"v": 91
		},
		{
			"x": 3,
			"y": 12,
			"v": 100
		},
		{
			"x": 3,
			"y": 13,
			"v": 110
		},
		{
			"x": 3,
			"y": 14,
			"v": 126
		},
		{
			"x": 3,
			"y": 15,
			"v": 129
		},
		{
			"x": 3,
			"y": 16,
			"v": 114
		},
		{
			"x": 3,
			"y": 17,
			"v": 123
		},
		{
			"x": 3,
			"y": 18,
			"v": 146
		},
		{
			"x": 3,
			"y": 19,
			"v": 97
		},
		{
			"x": 3,
			"y": 20,
			"v": 9
		},
		{
			"x": 3,
			"y": 21,
			"v": 11
		},
		{
			"x": 3,
			"y": 22,
			"v": 15
		},
		{
			"x": 3,
			"y": 23,
			"v": 12
		},
		{
			"x": 4,
			"y": 0,
			"v": 2
		},
		{
			"x": 4,
			"y": 1,
			"v": 0
		},
		{
			"x": 4,
			"y": 2,
			"v": 0
		},
		{
			"x": 4,
			"y": 3,
			"v": 0
		},
		{
			"x": 4,
			"y": 4,
			"v": 0
		},
		{
			"x": 4,
			"y": 5,
			"v": 0
		},
		{
			"x": 4,
			"y": 6,
			"v": 8
		},
		{
			"x": 4,
			"y": 7,
			"v": 38
		},
		{
			"x": 4,
			"y": 8,
			"v": 73
		},
		{
			"x": 4,
			"y": 9,
			"v": 94
		},
		{
			"x": 4,
			"y": 10,
			"v": 114
		},
		{
			"x": 4,
			"y": 11,
			"v": 115
		},
		{
			"x": 4,
			"y": 12,
			"v": 124
		},
		{
			"x": 4,
			"y": 13,
			"v": 77
		},
		{
			"x": 4,
			"y": 14,
			"v": 99
		},
		{
			"x": 4,
			"y": 15,
			"v": 131
		},
		{
			"x": 4,
			"y": 16,
			"v": 119
		},
		{
			"x": 4,
			"y": 17,
			"v": 125
		},
		{
			"x": 4,
			"y": 18,
			"v": 142
		},
		{
			"x": 4,
			"y": 19,
			"v": 89
		},
		{
			"x": 4,
			"y": 20,
			"v": 20
		},
		{
			"x": 4,
			"y": 21,
			"v": 12
		},
		{
			"x": 4,
			"y": 22,
			"v": 27
		},
		{
			"x": 4,
			"y": 23,
			"v": 2
		},
		{
			"x": 5,
			"y": 0,
			"v": 4
		},
		{
			"x": 5,
			"y": 1,
			"v": 3
		},
		{
			"x": 5,
			"y": 2,
			"v": 2
		},
		{
			"x": 5,
			"y": 3,
			"v": 0
		},
		{
			"x": 5,
			"y": 4,
			"v": 0
		},
		{
			"x": 5,
			"y": 5,
			"v": 0
		},
		{
			"x": 5,
			"y": 6,
			"v": 2
		},
		{
			"x": 5,
			"y": 7,
			"v": 33
		},
		{
			"x": 5,
			"y": 8,
			"v": 53
		},
		{
			"x": 5,
			"y": 9,
			"v": 82
		},
		{
			"x": 5,
			"y": 10,
			"v": 107
		},
		{
			"x": 5,
			"y": 11,
			"v": 108
		},
		{
			"x": 5,
			"y": 12,
			"v": 110
		},
		{
			"x": 5,
			"y": 13,
			"v": 114
		},
		{
			"x": 5,
			"y": 14,
			"v": 109
		},
		{
			"x": 5,
			"y": 15,
			"v": 127
		},
		{
			"x": 5,
			"y": 16,
			"v": 101
		},
		{
			"x": 5,
			"y": 17,
			"v": 145
		},
		{
			"x": 5,
			"y": 18,
			"v": 123
		},
		{
			"x": 5,
			"y": 19,
			"v": 101
		},
		{
			"x": 5,
			"y": 20,
			"v": 60
		},
		{
			"x": 5,
			"y": 21,
			"v": 19
		},
		{
			"x": 5,
			"y": 22,
			"v": 18
		},
		{
			"x": 5,
			"y": 23,
			"v": 10
		},
		{
			"x": 6,
			"y": 0,
			"v": 4
		},
		{
			"x": 6,
			"y": 1,
			"v": 3
		},
		{
			"x": 6,
			"y": 2,
			"v": 0
		},
		{
			"x": 6,
			"y": 3,
			"v": 8
		},
		{
			"x": 6,
			"y": 4,
			"v": 2
		},
		{
			"x": 6,
			"y": 5,
			"v": 0
		},
		{
			"x": 6,
			"y": 6,
			"v": 7
		},
		{
			"x": 6,
			"y": 7,
			"v": 17
		},
		{
			"x": 6,
			"y": 8,
			"v": 28
		},
		{
			"x": 6,
			"y": 9,
			"v": 67
		},
		{
			"x": 6,
			"y": 10,
			"v": 110
		},
		{
			"x": 6,
			"y": 11,
			"v": 67
		},
		{
			"x": 6,
			"y": 12,
			"v": 147
		},
		{
			"x": 6,
			"y": 13,
			"v": 105
		},
		{
			"x": 6,
			"y": 14,
			"v": 142
		},
		{
			"x": 6,
			"y": 15,
			"v": 121
		},
		{
			"x": 6,
			"y": 16,
			"v": 111
		},
		{
			"x": 6,
			"y": 17,
			"v": 85
		},
		{
			"x": 6,
			"y": 18,
			"v": 91
		},
		{
			"x": 6,
			"y": 19,
			"v": 34
		},
		{
			"x": 6,
			"y": 20,
			"v": 27
		},
		{
			"x": 6,
			"y": 21,
			"v": 22
		},
		{
			"x": 6,
			"y": 22,
			"v": 27
		},
		{
			"x": 6,
			"y": 23,
			"v": 17
		}
		]
		function invertHourVehicleCountData(data){
			let invertedData = []
			for (let i = 0; i < data.length; i++) {
				invertedData.push(
					{
						x: data[i].x == 1 ? 5 : data[i].x == 5 ? 1 : data[i].x,
						// y: data[i].y > 3 ? 23-data[i].y + 3 : 0,
						y: data[i].y > 2 ? 23-data[i].y + 3 : data[i].y,
						v: data[i].v
					}
				)
			}
			return invertedData
		}
		const southVehicleCountData = invertHourVehicleCountData(northVehicleCountData)
		function getRandomInt(min, max) {
			min = Math.ceil(min);
			max = Math.floor(max);
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}
		function categoriesCongestion(data){
			let categorisedData = []
			for (let i = 0; i < data.length; i++) {
				const v = data[i].v
				let newV = 0
				let randomPercentage = 0
				if (v >= 0.8*150){
					newV = 5
					randomPercentage = getRandomInt(81, 96)
				} else if (v >= 0.6*150){
					newV = 4
					randomPercentage = getRandomInt(61, 80)
				} else if (v >= 0.4*150){
					newV = 3
					randomPercentage = getRandomInt(41, 60)
				} else if (v >= 0.2*150){
					newV = 2
					randomPercentage = getRandomInt(21, 40)
				} else {
					newV = 1
					randomPercentage = getRandomInt(1, 20)
				}
				categorisedData.push(
					{
						x: data[i].x,
						y: data[i].y,
						v: newV,
						random_percentage: randomPercentage
					}
				)
			}
			return categorisedData
		}
		const northCongestionLevelData = categoriesCongestion(northVehicleCountData)
		const southCongestionLevelData = categoriesCongestion(southVehicleCountData)
		////////////////////////////////////////////////////////////////////////////


		let chart = null
		function showPercentageFunc(context){
			if (!props.isVehicleCount){
				if (props.showPercentage){
					return context.dataset.data[context.dataIndex].random_percentage + '%'
				}
				return ''
			}
			return ''
		}

		function renderChart(){
			const ctxChart = document.getElementById('vehicleCountChartBig').getContext('2d');
			const chart = new Chart(ctxChart, {
				type: 'matrix',
				data: {
					datasets: [{
						label: 'Vehicle Count',
						data: [{
							x: '',
							y: '',
							v: '',
						}],
						backgroundColor(context) {
							if (store.state.matrixDetailsData.data.vehicle_count_max == 0){
								return `rgba(255, 48, 55, 0)`
							} else {
								if (props.isVehicleCount){
									const value = context.dataset.data[context.dataIndex].v
									// const valueOverMax = value / store.state.matrixDetailsData.data.vehicle_count_max
									const valueOverMax = value/150
									const alpha = valueOverMax
									return `rgba(217, 11, 11, ${alpha})`
								} else {
									const v = context.dataset.data[context.dataIndex];
									if (v.v == 1){
										return 'rgba(1, 128, 2, 1)'
									} else if (v.v == 2){
										return 'rgba(254, 254, 0, 1)'
									} else if (v.v == 3){
										return 'rgba(255, 165, 0, 1)'
									} else if (v.v == 4){
										return 'rgba(255, 0, 0, 1)'
									} else if (v.v == 5){
										return 'rgba(166, 15, 15, 1)'
									} else {
										// no data
										return 'rgba(255, 255, 255, 1)'
									}
								}
								
							}
						},
						borderColor(context) {
							return `rgba(255, 255, 255, 0.2)`
						},
						borderWidth: 1,
						borderWidth: 1,
						width: ({chart}) => {
							return chart.chartArea.width/7
						},
						height: ({chart}) => {
							return (chart.chartArea.height/24)
						},
					}],
				},
				options: {
					maintainAspectRatio: false,
					responsive: true,
					plugins: {
						legend: false,
						title:{
							display: false,
							text: 'Vehicle Count'
						},
						datalabels: {
							color: '#292929',
							formatter: function(value, context) {
								return showPercentageFunc(context)
							}
						},
						tooltip: {
							displayColors: true,
							callbacks: {
								title(context){
									const dataIndex = context[0].dataIndex
									const hourIndex = context[0].raw.y
									const dayIndex = context[0].raw.x
									return daysOfWeek[dayIndex] + ', ' + hours[hourIndex]
								},
								label(context){
									const v = context.dataset.data[context.dataIndex];
									if (props.isVehicleCount){
										return `# of Vehicle: ${v.v}`
									} else {
										var tooltipText = ''
										if (v.v == 1){
											tooltipText = 'Free Flow'
										} else if (v.v == 2){
											tooltipText = 'Light Traffic'
										} else if (v.v == 3){
											tooltipText = 'Moderate Traffic'
										} else if (v.v == 4){
											tooltipText = 'Heavy Traffic'
										} else if (v.v == 5){
											tooltipText = 'Bumper to Bumper'
										} else {
											//no data
											tooltipText = 'No Data'
										}
										return `Congestion Level: ${tooltipText} (${v.random_percentage}%)`
									}
								}
							}
						}
					},
					scales: {
						x: {
							position: 'top',
							ticks: {
								padding: 15,
								stepSize: 1,
								// labelOffset: -10,
								align: 'center',
								autoSkip: true,
								callback: (value, index, ticks) => {
									return daysOfWeek[index];
								}
							},
							grid: {
								display: false,
								drawBorder: false
							}
						},
						y: {
							// offset: true,
							ticks: {
								autoSkip: true,
								stepSize: 2,
								// maxTicksLimit: 12,
								callback: (value, index, ticks) => {
									return hours[value];
								}
							},
							grid: {
								display: false,
								drawBorder: false
							}
						}
					}
				},
				plugins: [ChartDataLabels]
			});
			return chart
		}
		
		onMounted(() => {
			chart = renderChart()
			// use dummy data
			if (props.isVehicleCount){
				if (props.isSouthBound){
					chart.data.datasets[0].data = southVehicleCountData
				} else {
					chart.data.datasets[0].data = northVehicleCountData
				}
			} else{
				if (props.isSouthBound){
					chart.data.datasets[0].data = southCongestionLevelData
				} else {
					chart.data.datasets[0].data = northCongestionLevelData
				}
			}
			chart.update()
		})
		
		
		watch(() => [props.isVehicleCount, props.isSouthBound], () => {
			if (props.isVehicleCount){
				if (props.isSouthBound){
					chart.data.datasets[0].data = southVehicleCountData
				} else {
					chart.data.datasets[0].data = northVehicleCountData
				}
				
				// chart.data.datasets[0].data = store.state.matrixDetailsData.data.vehicle_count
			} else{
				if (props.isSouthBound){
					chart.data.datasets[0].data = southCongestionLevelData
				} else {
					chart.data.datasets[0].data = northCongestionLevelData
				}
				// chart.data.datasets[0].data = store.state.matrixDetailsData.data.congestion_level
			}
			chart.update()
		});

		// watch(() => props.isSouthBound, () => {
		// 	console.log(props.isSouthBound)
		// 	if (props.isSouthBound){
		// 		// chart.data.datasets[0].data = store.state.matrixDetailsData.data.vehicle_count
		// 		chart.data.datasets[0].data = southData
		// 		chart.update()
		// 	} else{
		// 		chart.data.datasets[0].data = northData
		// 		chart.update()
		// 	}
		// });

		watch(() => props.showPercentage, () => {
			chart.update()
		});
		// store.watch((state, getters) => [state.matrixDetailsData],  () => {
		// 	if (props.isVehicleCount){
		// 		// chart.data.datasets[0].data = store.state.matrixDetailsData.data.vehicle_count
		// 		chart.data.datasets[0].data = northData
		// 		chart.update()
		// 	} else{
		// 		chart.data.datasets[0].data = store.state.matrixDetailsData.data.congestion_level
		// 		chart.update()
		// 	}
		// })
	}
}


</script>

<style lang='scss'>
</style>