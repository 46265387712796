<template>
	<div class='py-4 bg-white '>
		<div v-if='!isDetailsPage' class="h-72">			
			<div  class="h-5/6">
				<span class='text-lg font-bold'>{{ route }}</span>
				<div class="my-2 border-dashed border border-1"></div>
				<div v-if='bulletData!=null' class='h-full grid grid-cols-2 gap-x-4 divide-x'>
					<Score header='Global Traffic Score' :isGlobal='true' :score='bulletData.global_index[1]' scoreTextSize='text-6xl' :trend='Math.abs(bulletData.global_index[1] - bulletData.global_index[0])'
						:isUp='(bulletData.global_index[1] - bulletData.global_index[0]) > 0' :isUpEqualBad='false' :scoreGrade='1'/>
					<Score header='Congestion Level' :score='`${generateRandomInRange(70, 85)}%`' scoreTextSize='text-6xl' :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
						:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='true' />
					
					<!-- <div class='grid grid-rows-1 divide-y '>
						<Score header='Travel Time' :score='bulletData.average_travel_time[1] + " Minutes"' :trend='Math.round(Math.abs(bulletData.average_travel_time[1] - bulletData.average_travel_time[0])/bulletData.average_travel_time[0]*100*10)/10 + "%"' 
						:isUp='(bulletData.average_travel_time[1] - bulletData.average_travel_time[0]) > 0' :isUpEqualBad='true' />
						<Score header='Travel Time' :score='generateRandomInRange(2, 8) + " Minutes"' :trend='Math.round(Math.abs(generateRandomInRange(2, 8) - generateRandomInRange(2, 8))/generateRandomInRange(2, 8)*100*10)/10 + "%"' 
						:isUp='(generateRandomInRange(2, 8) - generateRandomInRange(2, 8)) > 0' :isUpEqualBad='true' />
					</div> -->
				</div>
				<div v-else class='h-48'></div>
			</div>
		</div>
		<div v-else>
			<div v-if='bulletData!=null' class='h-96 grid grid-cols-4 gap-x-4 divide-x '>
			<Score header='Congestion Level' scoreTextSize='text-6xl' :score='`${generateRandomInRange(60, 95)}%`' :trend='Math.round(Math.abs(bulletData.vehicle_count[1] - bulletData.vehicle_count[0])/bulletData.vehicle_count[0]*100*10)/10 + "%"' 
				:isUp='(bulletData.vehicle_count[1] - bulletData.vehicle_count[0]) > 0' :isUpEqualBad='true' />
			<div class='col-span-3 grid grid-rows-2 divide-y '>
				<div class='grid grid-cols-3'>
					<Score header='Travel Time' :score='bulletData.average_travel_time[1] + " Minutes"' :trend='Math.round(Math.abs(bulletData.average_travel_time[1] - bulletData.average_travel_time[0])/bulletData.average_travel_time[0]*100*10)/10 + "%"' 
					:isUp='(bulletData.average_travel_time[1] - bulletData.average_travel_time[0]) > 0' :isUpEqualBad='true' />
					<Score header='Vehicle Count' :score='bulletData.vehicle_count[1]' :trend='Math.round(Math.abs(bulletData.vehicle_count[1] - bulletData.vehicle_count[0])/bulletData.vehicle_count[0]*100*10)/10 + "%"' 
					:isUp='(bulletData.vehicle_count[1] - bulletData.vehicle_count[0]) > 0' :isUpEqualBad='true' />
					<Score header='Traffic Speed' :score='`${bulletData.average_speed[1]} km/h`' :trend='Math.round(Math.abs(bulletData.average_speed[1] - bulletData.average_speed[0])/bulletData.average_speed[0]*100*10)/10 + "%"' 
					:isUp='(bulletData.average_speed[1] - bulletData.average_speed[0]) > 0' :isUpEqualBad='false'/>
				</div>
				<div class='grid grid-cols-3'>
					<Score header='Delay' :score='3 + " Minutes"' :trend='Math.round(Math.abs(bulletData.average_travel_time[1] - bulletData.average_travel_time[0])/bulletData.average_travel_time[0]*100*10)/10 + "%"' 
					:isUp='(bulletData.average_travel_time[1] - bulletData.average_travel_time[0]) > 0' :isUpEqualBad='true' />
					<Score header='Usual Delay' :score='5 + " Minutes"'  
					/>
					<Score header='Queue Length' :score='"97 Meter"' :trend='Math.round(Math.abs(bulletData.average_speed[1] + 8 - bulletData.average_speed[0])/bulletData.average_speed[0]*100*10)/10 + "%"' 
					:isUp='(bulletData.average_speed[1] - bulletData.average_speed[0]) > 0' :isUpEqualBad='true'/>
					
				</div>
			</div>
		</div>
		<div v-else class='h-48'></div>
		</div>
		
	</div>

<!-- <Score header='Overspeeding Vehicle' :score='bulletData.overspeeding_vehicle_count[1]' :trend='Math.round(Math.abs(bulletData.overspeeding_vehicle_count[1] - bulletData.overspeeding_vehicle_count[0])/bulletData.overspeeding_vehicle_count[0]*100*10)/10 + "%"' 
:isUp='(bulletData.overspeeding_vehicle_count[1] - bulletData.overspeeding_vehicle_count[0]) > 0' :isUpEqualBad='true' />
<Score header='Heavy Vehicle' :score='bulletData.heavy_vehicle_count[1]' :trend='Math.round(Math.abs(bulletData.heavy_vehicle_count[1] - bulletData.heavy_vehicle_count[0])/bulletData.heavy_vehicle_count[0]*100*10)/10 + "%"' 
:isUp='(bulletData.heavy_vehicle_count[1] - bulletData.heavy_vehicle_count[0]) > 0' :isUpEqualBad='true' />

<Score header='Carbon Emission' :score='bulletData.carbon_emission[1] + " c&#13221;"' :trend='Math.round(Math.abs(bulletData.carbon_emission[1] - bulletData.carbon_emission[0])/bulletData.carbon_emission[0]*100*100)/100 +"%"' 
:isUp='(bulletData.carbon_emission[1] - bulletData.carbon_emission[0]) > 0' :isUpEqualBad='true' /> -->
</template>
	
<script>
import { ref, onMounted  } from 'vue';
import Score from '@/components/Score.vue';

export default{
	name: 'RouteScore',
	// props: ['route', 'bulletData'],
	props: {
		route: String,
		bulletData: Object,
		isDetailsPage: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		Score
	},
	setup(){
		function generateRandomInRange(min, max) {
			min = Math.ceil(min);
			max = Math.floor(max);
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}

		return { generateRandomInRange }
	}
}

</script>