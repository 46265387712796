<template>
	<div class='flex justify-between items-center'>
		<router-link :to="{name : 'Overview'}">
			<div class='flex items-center'>
				<img src='../assets/scs.png'  class='h-12 inline-block' alt='scs logo'>
				<div class='hidden xl:inline ml-2 text-clip whitespace-nowrap'>
					<span class='text-xl font-bold flex '>Intelligent Traffic Management System</span>
					<span class=''>Traffic statistics dashboard for Subang Jaya.</span>
				</div>
			</div>
		</router-link>
		<div>
			<div class='h-full'>
				<router-link :to="{name : 'RouteDetails', params: {id: '5'}}" 
					class='px-4 py-3 bg-transparent hover:bg-slate-200 rounded-sm'>
					Congestions
				</router-link>
				<router-link :to="{name : 'ControlPanel'}" 
					class='ml-2 px-4 py-3 bg-transparent hover:bg-slate-200 rounded-sm'>
					Control Panel
				</router-link>
				<router-link :to="{name : 'CarbonEmission'}" 
					class='ml-2 px-4 py-3 bg-transparent hover:bg-slate-200 rounded-sm'>
					Carbon Emission
				</router-link>
				<router-link :to="{name : 'VehicleProfiling'}" 
					class='ml-2 px-4 py-3 bg-transparent hover:bg-slate-200 rounded-sm'>
					Vehicle Profiling
				</router-link>
				<router-link :to="{name : 'VehicleDetected'}" 
					class='ml-2 px-4 py-3 bg-transparent hover:bg-slate-200 rounded-sm'>
					Vehicle Detected
				</router-link>
			</div>
		</div>
	</div>

</template>

<script>
import { watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
	name: 'Header',
	setup(){
		const store = useStore()
		const route = useRoute()

		watch(route, (to) => {
			if (route.path.includes('/route/')) {
				store.commit('setHeaderActive', [true, false, false, false])
			} else if (route.path.includes('/control-panel')){
				store.commit('setHeaderActive', [false, true, false, false])
			} else if (route.path.includes('/simulation')){
				store.commit('setHeaderActive', [false, false, true, false])
			} else if (route.path.includes('/vehicle-detected')){
				store.commit('setHeaderActive', [false, false, false , true])
			} else {
				store.commit('setHeaderActive', [false, false, false, false])
			}
		})
	}
}
</script>

<style>
	

</style>