<template>
	<canvas id='vehicleCountChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex'
import Chart from 'chart.js/auto';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import dayjs from 'dayjs'

Chart.register(MatrixController, MatrixElement);

export default {
	name: 'VehicleCountChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		const daysOfWeek = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		]

		const hours = [
				'12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
				'6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
				'12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
				'6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
				'12 AM',
			]

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('vehicleCountChart').getContext('2d');
			const chart = new Chart(ctxChart, {
				type: 'matrix',
				data: {
					datasets: [{
						label: 'Vehicle Count',
						data: [{
							x: '',
							y: '',
							v: '',
						}],
						backgroundColor(context) {
							if (store.state.matrixDetailsData.data.vehicle_count_max == 0){
								return `rgba(255, 48, 55, 0)`
							} else {
								const value = context.dataset.data[context.dataIndex].v
								const alpha = value / store.state.matrixDetailsData.data.vehicle_count_max;
								return `rgba(255, 48, 55, ${alpha})`
							}
						},
						borderColor(context) {
							return `rgba(255, 48, 55, 0.2)`
						},
						borderWidth: 1,
						borderWidth: 1,
						width: ({chart}) => {
							return Math.max((chart.chartArea || {}).width / 23 - 1, (chart.chartArea || {}).height / 12 - 1)
						},
						height: ({chart}) => {
							return Math.max((chart.chartArea || {}).width / 23 - 1, (chart.chartArea || {}).height / 12 - 1)
						},
					}],
				},
				options: {
					plugins: {
						legend: false,
						title:{
							display: true,
							text: 'Vehicle Count'
						},
						tooltip: {
							displayColors: true,
							callbacks: {
								title(context){
									const dataIndex = context[0].dataIndex
									const hourIndex = context[0].raw.x
									const dayIndex = context[0].raw.y
									return daysOfWeek[dayIndex] + ', ' + hours[hourIndex]
								},
								label(context){
									const v = context.dataset.data[context.dataIndex];
									return `# of Vehicle: ${v.v}`
								}
							}
						}
					},
					scales: {
						x: {
							ticks: {
								stepSize: 1,
								labelOffset: -10,
								align: 'start',
								autoSkip: true,
								callback: (value, index, ticks) => {
									return hours[index];
								}
							},
							grid: {
								display: false
							}
						},
						y: {
							offset: true,
							ticks: {
								autoSkip: false,
								stepSize: 1,
								callback: (value, index, ticks) => {
									return daysOfWeek[value];
								}
							},
							grid: {
								display: false
							}
						}
					}
				}
			});
			return chart
		}
		
		onMounted(() => {
			chart = renderChart()
		})

		store.watch((state, getters) => [state.matrixDetailsData],  () => {
			chart.data.datasets[0].data = store.state.matrixDetailsData.data.vehicle_count
			chart.update()
		})
	}
}
</script>

<style lang='scss'>

</style>