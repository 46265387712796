<template>
<div class='mt-8 flex flex-col overflow-clip'>
	<div class='grid grid-cols-3 w-full h-fit gap-x-2'>
		<div class='col-span-3 xl:col-span-2 bg-transparent h-fit rounded-sm'>
			<div class='w-full h-[58rem] bg-white py-4 px-8 flex flex-col'>
				<div class='mt-4 mb-2 flex justify-between'>
					<div>
						<span class='text-2xl font-black text-black'>Subang Jaya Traffic</span>
						<br>
						<div class='flex items-center mt-2'>
							<img src='../assets/malaysia.png' alt='' class='h-5'>
							<span class='ml-2 text-lg font-light'>Malaysia</span>
						</div>
					</div>
				</div>
				<!-- <hr class="mt-4"/> -->
				<div class="grid grid-cols-3 mt-8 grow divide-x-2 divide-slate-100">
					<div class="flex justify-between">
						<!-- <div class="h-full w-0.5 bg-slate-100"></div> -->
						<div class="flex flex-col pr-4 h-full ">
							<div class="flex flex-col">
								<div class="flex items-center">
									<span class='font-light text-lg'>Global Traffic Score (Last Hour)</span>
									<div v-tippy="'Global Traffic Score is an overview metric that aggregates certain feature such as congestion level, travel time, traffic speed and population density.'" class="ml-2">
										<svg  xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 stroke-blue-600 hover:stroke-blue-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
											<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
										</svg>
									</div>
								</div>	
								<div class='flex items-center mt-6'>
									<img src='../assets/global.png' class='h-14 w-14' alt='Smart Selangor Logo'/>
									<span class='font-bold text-5xl ml-6'>91</span>
								</div>
								<div class='mt-4 font-semibold text-lg'>
									<span class='text-emerald-600'>&#8593; 3</span>
									<span> from the past hour</span>
								</div>
							</div>
							<div class="h-0.5 bg-slate-100 w-full my-5"></div>
							<div class="">
								<span class='font-light text-lg '>Congestion Level (Last Hour)</span>
								<div class='flex items-center mt-6'>
									<img src='../assets/traffic-jam.png' class='h-14 w-14' alt='Smart Selangor Logo'/>
									<span class='font-bold text-5xl ml-6'>87%</span>
								</div>
								<div class='mt-4 font-semibold text-lg'>
									<span class='text-red-600'>&#8593; 6%</span>
									<span> from the past hour</span>
								</div>
							</div>
							<div class="h-0.5 bg-slate-100 w-full my-5"></div>
							<div class="">
								<span class='font-light text-lg'>Traffic Jams Length (Last Hour)</span>
								<div class='flex items-center mt-6'>
									<img src='../assets/road.png' class='h-14 w-14' alt='Smart Selangor Logo'/>
									<span class='font-bold text-4xl ml-6'>9.7 KM</span>
								</div>
								<div class='mt-4 font-semibold text-lg'>
									<!-- <span class='text-red-600'>&#8593; 6%</span> -->
									<span>out of</span>
									<span > 15.7 KM</span>
									<span> of total road length</span>
								</div>
							</div>
							<div class="h-0.5 bg-slate-100 w-full my-5"></div>
							<div class="">
								<span class='font-light text-lg '>Time Saved (Last Hour)</span>
								<div class='flex items-center mt-6'>
									<img src='../assets/clock.png' class='h-14 w-14' alt=''/>
									<span class='font-bold text-3xl ml-6'>23 Minutes</span>
								</div>
								<div class='mt-4 font-semibold text-lg'>
									<span class='text-green-600'>&#8593; 6%</span>
									<span> from the past hour</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-span-2 flex flex-col">
						<div v-if='allRouteBulletData.length==routeList.length' class='h-1/2 ml-4'>
							<div class='bg-white'>
								<span class='font-light text-xl'>Route Traffic Statistics</span>
							</div>
							<swiper
								:modules="modules"
								:slides-per-view='1'
								:space-between='8'
								:mousewheel= 'true'
								@swiper='onSwiper'
								@slideChange='onSlideChange'
								:scrollbar='{ draggable: true }'
								:autoplay='{
									"delay": 4000,
									"disableOnInteraction": false
								}'
								>
								<swiper-slide :value='route.name' v-for='route in routeList'>
									<router-link :to='{name : "RouteDetails", params: {id: route.id}}' >
										<RouteScore :route='route.name'
										:bulletData='allRouteBulletData.find(x => x.routeId == route.id).data'/>
									</router-link>
									<div class=''></div>
								</swiper-slide>
							</swiper>
						</div>
						<div v-else class="h-1/2 w-full">
							
						</div>
						<div class="h-0.5 bg-slate-100 w-full -mt-5"></div>
						<div class="h-1/2 pt-4 pb-6 ml-4">
							<span class=' font-light text-xl'>Traffic Flow</span>
							<FrontCongestionChart/>
						</div>	
					</div>
				</div>
			</div>
		</div>
		<div class='hidden xl:inline col-span-0 xl:col-span-1 grow rounded-sm '>
			<Map class=''></Map>
		</div>
	</div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y, Mousewheel, Autoplay  } from 'swiper';
import SwiperCore from 'swiper/core';

import Score from '@/components/Score.vue';
import RouteScore from '@/components/RouteScore.vue';
import Map from '@/components/Map.vue';
import LiveTraffic from '@/components/LiveTraffic.vue';
import FrontCongestionChart from '@/charts/FrontCongestionChart.vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';

export default {
	name: 'OverviewPage',
	components: {
		Score,
		RouteScore,
		Map,
		LiveTraffic,
		Swiper,
		SwiperSlide,
		FrontCongestionChart
	},
	setup(){
		const store = useStore()
		const routeList = store.state.routeList
		const allRouteBulletData = ref([])

		const getAllRouteBulletData = async () => {
			allRouteBulletData.value = []
			for (const index in routeList){
				const route = routeList[index]
				axios
					.get(`/last-hour/route/${route.id}`, {
						params: {

						}})
					.then(response => {
						allRouteBulletData.value.push({
							routeId: route.id,
							data: response.data.data
						})
					})
					.catch(error => {
						console.log(error)
					}
				)
			}
		}

		onMounted(() => {
			getAllRouteBulletData()
		})
			
		return { routeList, allRouteBulletData, 
			modules: [Navigation, Pagination, Scrollbar, A11y, Mousewheel, Autoplay], }
	}
}

</script>