<template>
	<div class='mt-8 flex items-center justify-between w-full xl:w-2/3'>
		<span class='text-xl'>{{ routeList.find(route => route.id == $store.state.routeId).name }}</span>
		<div class="bg-white pr-4">
			<dropdown placement="bottom" :text="`${routeList.find(route => route.id == $store.state.routeId).name}&nbsp;&nbsp;`" class="dropdown w-full route-dropdown">
				<ul class="ml-auto py-1 text-sm text-gray-700">
					<li :value='route.name' :key='route' v-for='route in routeList'>
						<a @click='redirectRouteId(route.id)' href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{ route.name }}</a>
					</li>
				</ul>
			</dropdown>
		</div>
		
		<!-- <div>
			<button id="dropdownDefault" data-dropdown-toggle="dropdown" class="text-slate-800 bg-white mr-0 hover:bg-slate-200 focus:ring-2 focus:outline-none focus:ring-slate-300 font-medium rounded-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">{{ routeList.find(route => route.id == $store.state.routeId).name }}<svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
			Dropdown menu
			<div id="dropdown" class="hidden z-10 w-80 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
				<ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
					<li :value='route.name' :key='route' v-for='route in routeList'>
						<a @click='redirectRouteId(route.id)' href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{ route.name }}</a>
					</li>
				</ul>
			</div>
		</div> -->
	</div>
</template>

<script>
import { onMounted, onUpdated, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
import { Dropdown } from 'flowbite-vue'

export default {
	name: 'RouteDropdown',
	props: ['id'],
	components: {
		Dropdown
	},	
	setup(){
		const store = useStore()
		const route = useRoute();
		const router = useRouter();
		const routeList = store.state.routeList

		function setRouteId(value) {
			store.commit('setRouteId', value)
		}

		setRouteId(route.params.id)

		function redirectRouteId(id){
			router.push({name : 'RouteDetails', params: {id: id}})
			setRouteId(id)
		}

		return { routeList, setRouteId, redirectRouteId }
	}
}

</script>

<style>
	.route-dropdown button {
		@apply py-3
	}
</style>