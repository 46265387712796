<template>
	<canvas id='vehicleOutChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';

export default {
	name: 'VehicleOutChart',
	components: {
		
	},
	setup(){
		const store = useStore()
		const hours = [
				'12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
				'6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
				'12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
				'6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
				'12 AM',
			]

		let data = [
			100,80,50,50,70,180,270,380,780,710,450,380, 480,470,410,360,610,790,510,450,280,150,
			350, 150, 120
		]

		function limitQueueData(data){
			const currentHour = dayjs().hour()
			for (let i = currentHour; i < data.length; i++) {
				data[i] = null
			}
			return data
		}

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('vehicleOutChart').getContext('2d');

			const chart = new Chart(ctxChart, {
				type: 'bar',
				
				data: {
					labels: hours,
					datasets: [{
						label: '1st Penang Bridge',
						data: limitQueueData(data),
						backgroundColor: 'rgba(255, 99, 132, 0.5)',
						borderColor: 'rgba(75, 192, 192, 1))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0
					},
					{
						label: '2nd Penang Bridge',
						data: limitQueueData(data),
						backgroundColor: 'rgba(255, 159, 64, 0.5)',
						borderColor: 'rgba(75, 192, 192, 1))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0
					}
				]
				},
				options: {
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
					},
					maintainAspectRatio: false,
					responsive: true,
					scales: {
						x: {
							stacked: true,
							ticks: {
								maxTicksLimit: 12
							}
						},
						y: {
							stacked: true,
							beginAtZero: true,
							suggestedMax: 1000,
							// grace: 100,
							ticks: {
								maxTicksLimit: 10,
								// callback: (value, index, ticks) => {
								// 	return value+'%';
								// }
							}
						}
					},
					plugins: {
						legend: {
							// display: false
						},
						tooltip: {
							displayColors: true,
							callbacks: {
								label(context){
									const dataset = context.dataset.label
									const v = context.dataset.data[context.dataIndex]
									return `${dataset}: ${v}`
								}
							}
						}
					}
				}
			});
			return chart	
		}
		onMounted(() => {
			chart = renderChart()
		})
	}
}
</script>

<style lang='scss'>

</style>