<template>
	<div class='w-full h-16 md:h-24 grid justify-items-center bg-slate-700'>
		<div class='my-auto flex items-center'>
			<span class=' text-white text-center '>Powered by</span>
			<img src='../assets/enygma_white.png'  class='h-10 inline-block ml-1 mr-1 mt-1' alt='engyma.ai logo'>
			<!-- <span class=' text-white text-center'>with</span>
			<span class='text-red-600 text-2xl ml-1'> ♥</span> -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'CustomFooter',
	setup(){
		
	}
}
</script>