<template>
	<canvas id='globalChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';

export default {
	name: 'GlobalChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('globalChart').getContext('2d');

			const chart = new Chart(ctxChart, {
				type: 'line',
				data: {
					datasets: [{
						data: [47, 45, 43, 49, 31, 34, 45, 43, 49, 31, 34, 45, 51, 46, 34, 51, 46, 41, 35, 24, 29, 37, 44, 47, 45, ],
						backgroundColor: 'rgba(37,99,235, 0.1)',
						borderColor: 'rgba(255, 99, 132, 0.2))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 2,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					},
					// {
					// 	label: 'Average for Year 2021',
					// 	data: [47, 41, 35, 24, 29, 37, 44, 47, 45, 43, 49, 31, 34, 45, 51, 46, 45, 43, 49, 31, 34, 45, 51, 46, 56],
					// 	backgroundColor: 'rgba(51, 255, 228, 0.1)',
					// 	borderColor: 'rgba(255, 99, 132, 0.2))',
					// 	borderDash: [5, 5],
					// 	fill: true,
					// 	borderWidth: 1,
					// 	barPercentage: 1.0,
					// 	categoryPercentage: 1.0,
					// 	pointRadius: 0,
					// 	lineTension: 0.1
					// }
				]
				},
				options: {
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
						},
					plugins: {
						legend: true,
						// title:{
						// 	display: true,
						// 	text: 'Peace Index',
						// 	padding: 4
						// },
						tooltip: {
							displayColors: true,
							callbacks: {
								title(context){
									const datetime_obj = dayjs(context[0].raw.x)
									if (store.state.detailsData.is_daily_data){
										return datetime_obj.format('D MMM YYYY')
									} else {
										return datetime_obj.format('D MMM YYYY, h A')
									}
								},
								label(context){
									const dataset = context.dataset.label
									const v = context.dataset.data[context.dataIndex]
									return `${dataset}: ${v.y}`
								}
							}
						}
					},
					responsive: true,
					scales: {
						x: {
							type: 'time',
							time: {
								unit: 'hour'
							},
							offset: true,
							title: {
								display: true,
								text: 'Time'
							},
							grid: {
								display: false,
							},
							ticks: {
								align: 'start',
								autoSkip: true,
								// labelOffset: 0,
								// maxTicksLimit: 9,
								// count: 7,
								font: {
									size: 12
								},
								callback: function(value, index, ticks){
									if (value.includes('AM') || value.includes('PM')){
										const result = [value.slice(0, -2), value.slice(-2)]
										return result[0] + ' ' + result[1]
									} else {
										// check if year, remove year from the value (May 2022 -> May)
										if (parseInt(value.split(' ')[1]) > 100) {
											return value.split(' ')[0]
										} else {
											return value
										}
									}		
								}
							}
						},
						y:{
							suggestedMax: 100,
							min: 0,
							title: {
								display: true,
								text: ''
							},
							ticks: {
								beginAtZero: true,
								callback: function(value, index, ticks){
									return value
								}
							}
						}
					}
				},
			});
			return chart	
		}
		onMounted(() => {
			chart = renderChart()
		})

		store.watch((state, getters) => [state.detailsData],  () => {
			chart.data.datasets[0].data = store.state.detailsData.data.global_index
			chart.data.datasets[0].label = store.state.timeframeLabel
			if (store.state.detailsData.is_daily_data){
				if (store.state.detailsData.num_day > 120){
					chart.options.scales.x.time.unit = 'month'
					// set to no limit actually
					chart.options.scales.x.ticks.maxTicksLimit = 100
				} else if (store.state.detailsData.num_day > 25){
					chart.options.scales.x.time.unit = 'day'
					chart.options.scales.x.ticks.maxTicksLimit = 14
				} else {
					chart.options.scales.x.time.unit = 'day'
					chart.options.scales.x.ticks.maxTicksLimit = 9
				}
				
			} else {
				chart.options.scales.x.time.unit = 'hour'
				chart.options.scales.x.ticks.maxTicksLimit = 9
			}
			chart.update()
		})
	}
}
</script>

<style lang='scss'>

</style>