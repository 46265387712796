<template>
	<div class='mx-auto w-2/3'>
		<div class='flex'>
			<span class='mx-auto text-sm mb-1'>Number of Vehicles</span>
		</div>
		<div class='h-4 bg-gradient-to-r from-red-100 to-red-600'></div>
		<div class='flex justify-between text-xs font-thin'>
			<span>0</span>
			<span>{{ midPoint }}</span>
			<span>{{ endPoint }}</span>
		</div>
	</div>
</template>

<script>
import { watchEffect, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'VehicleCountLegend',
	setup(){
		const store = useStore()

		const midPoint = ref(5)
		const endPoint = ref(10)

		store.watch((state, getters) => [store.state.matrixDetailsData],  () => {
			if (store.state.matrixDetailsData.data.vehicle_count_max == 0){
				midPoint.value = 5
				endPoint.value = 10
			} else {
				midPoint.value = Math.round(store.state.matrixDetailsData.data.vehicle_count_max/2)
				endPoint.value = store.state.matrixDetailsData.data.vehicle_count_max
			}
		})
		
		return { midPoint, endPoint }
	}
}
</script>

<style lang='scss'>

</style>