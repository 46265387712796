<template>
	<div class='py-4 flex flex-col items-center justify-between mr-2'>
		<div class="flex justify-between items-center">
			<span class='font-semibold text-center text-lg'>{{ header }}</span>
			<div  v-if='isGlobal' v-tippy="'Global Traffic Score is an overview metric that aggregates certain feature such as congestion level, travel time, traffic speed and population density.'" class="ml-2">
				<svg  xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 stroke-blue-600 hover:stroke-blue-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
					<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
				</svg>
			</div>
		</div>
		
		<div class='flex items-center mx-auto pr-2'>
			<div class='bg-yellow-200 w-4 h-4 rounded-full'></div>
			<span :class='scoreTextSize+" "+scoreTextColor + " ml-4 font-bold"'>{{ score }}</span>
		</div>

		<div v-if='String(trend)!=""'>	
			<div v-if='String(trend) == "0" || String(trend).includes("NaN")' class='text-center'>
				<span>Same with the past hour</span>
			</div>
			<div v-else  class='text-center'>
				<span v-if='isUp' :class='trendTextColor + " font-semibold"'>&#8593;{{ String(trend).includes('NaN') ? 0 : trend }}</span>
				<span v-else :class='trendTextColor + " font-semibold"'>&#8595;{{ String(trend).includes('NaN') ? 0 : trend }}</span>
				<span> from the past hour</span>
			</div>
		</div>
		<div v-else>
			<span>&nbsp;</span>
		</div>
	</div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
	name: 'Score',
	// props: ['header', 'score', 'trend', 'isBad', 'isScoreLong'],
	components: {
	},
	props: {
		header: String,
		score: String,
		isGlobal: {
			type: Boolean,
			required: false,
			default: false
		},
		trend: {
			type: String,
			required: false,
			default: ''
		},
		isUp: Boolean,
		isUpEqualBad: Boolean,
		isBad: Boolean,
		scoreGrade: {
			type: Number,
			required: false,
			default: 0
		},
		scoreTextSize: {
			type: String,
			required: false,
			default: 'text-2xl'
		},
		isScoreLong: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	setup(props){
		const trendTextColor = ref('')
		const scoreTextColor = ref()
		const icon = ref()

		onMounted(() => {
			if (props.isUpEqualBad) {
				if (props.isUp){
					trendTextColor.value = 'text-red-600'
				} else {
					trendTextColor.value = 'text-green-600'
				}
			} else {
				if (props.isUp){
					trendTextColor.value = 'text-green-600'
				} else {
					trendTextColor.value = 'text-red-600'
				}
			}

			if (props.scoreGrade==1){
				scoreTextColor.value = 'text-green-600'
			} else if (props.scoreGrade==2){
				scoreTextColor.value = 'text-amber-400'
			} else if  (props.scoreGrade==3){
				scoreTextColor.value = 'text-red-600'
			}
		})

		return { trendTextColor, scoreTextColor }
	}
}

</script>