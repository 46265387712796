<template>
	<transition name='toast'>
		<div v-if='showToast' class='fixed right-8 bottom-8'>
			<div class='flex items-center p-4 w-full max-w-sm text-gray-500 bg-white border-2 border-rose-300 rounded-lg shadow-lg '>
				<router-link :to='{name : "VehicleDetected"}' class='flex items-center' @click='$emit("close")'>
					<div class='inline-flex flex-shrink-0 w-12 h-12'>
						<img src='../assets/warning.png' alt=''>
						<span class='sr-only'>warning icon</span>
					</div>
					<div class='ml-4 text-sm font-normal mt-1'>
						<span class='font-bold'>{{generateString(3)}} {{ generateRandomInt(1000, 9999) }} </span>
						<span > ({{ Math.random() < 0.5 ? 'reported stolen vehicle' : 'reported stolen vehicle'}}) was detected by </span>
						<span class='font-bold'>Camera {{generateRandomInt(1, 7)}}</span>
						<span> at </span>
						<span class='font-bold'>{{ dayjs().format('HH:mm') }}</span>
					</div>
				</router-link>
				
				<button @click='$emit("close")' type='button' class='p-2 bg-transparent text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 hover:bg-gray-100 inline-flex h-8 w-8 '>
					<span class='sr-only'>Close</span>
					<svg aria-hidden='true' class='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd'></path></svg>
				</button>
			</div>
		</div>
	</transition>
</template>

<script>
import { ref, watch } from 'vue';
import dayjs from 'dayjs';

export default {
	name: 'ToastNotification',
	props: {
		showToast: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	setup(props){

		function generateRandomInt(min,max) {
			return Math.floor((Math.random())*(max-min+1))+min;
		}

		const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';

		function generateString(length) {
			let result = ' ';
			const charactersLength = characters.length;
			for ( let i = 0; i < length; i++ ) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}

			return result;
		}
		// watch(() => props.showToast, () => {
		// 	if (props.showToast){
				
		// 	} else{
		// 		chart.data.datasets[0].data = store.state.matrixDetailsData.data.congestion_level
		// 		chart.update()
		// 	}
		// });

		return { dayjs, generateRandomInt, generateString }
	}
}
</script>

<style>
	.toast-enter-from{
		@apply opacity-0 translate-y-8
	}
	.toast-enter-to {
		@apply opacity-100 translate-y-0
	}	
	.toast-enter-active{
		@apply transition-all
	}

	.toast-leave-from{
		@apply opacity-100 translate-y-0
	}
	.toast-leave-to{
		@apply opacity-0 translate-y-8
	}
	.toast-leave-active{
		@apply transition-all
	}

</style>