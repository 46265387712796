<template>
	<div class="mt-8 h-[80vh] w-full min-h-screen ">
		<div class="p-4 bg-white">
			<span class="text-xl">Carbon Emission Reports</span>
		</div>
		<div class="grid grid-cols-2 grid-rows-2 gap-1 mt-2">
			<div class="bg-white p-4 flex flex-col">
				<span class="font-bold text-xl">Carbon Emission Reduction Goal</span>
				<div class="mt-4 grow">
					<CarbonChart/>
				</div>
			</div>
			<div class="bg-white p-4 grid grid-cols-2 ">
				<div class="flex flex-col justify-between h-5/6 my-auto pl-4">
					<span class="text-8xl font-bold text-green-700">245 trees</span>
					<span class="text-xl font-bold clear-left"> are needed to neutralise the carbon footprint produced by the traffic.</span>
				</div>
				<div class="flex flex-col justify-center h-5/6 my-auto">
					<img class="float-right h-full w-full object-contain" src='../assets/tree.png'  alt=''/>
				</div>
			</div>
			<div class="bg-white p-4">
				<div class="flex items-center">
					<span class="font-bold text-xl">Carbon Emission Saved</span>
					<div  v-tippy="'Saved due to the reduction in traffic congestion and vehicles on the road.'" class="ml-2">
						<svg  xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 stroke-blue-600 hover:stroke-blue-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
							<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
						</svg>
					</div>
				</div>
				<div class="w-full flex">
					<div class="w-2/3 mx-auto ">
						<div class="-mt-4 mx-auto relative">
							<CarbonGaugeChart/>
							<div class="flex flex-col absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<span class="text-7xl font-bold text-center">83%</span>
								<span class="text-center">51 kg CO<sub>2</sub> left to reach our goal.</span>
							</div>
						</div>
						<div class="flex justify-between">
							<div class="flex flex-col items-center -ml-4 -mt-4">
								<span>Total saved</span>
								<span class="font-bold text-lg">249 kg CO<sub>2</sub></span>
							</div>
							<div class="flex flex-col items-center -mr-4 -mt-4">
								<span>Goal</span>
								<span  class="font-bold text-lg">300 kg CO<sub>2</sub></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-white  p-4">
				<span class="font-bold text-xl">Carbon Emission Distribution</span>
				<div class="grid grid-cols-3 mt-6">
					<div class="flex flex-col justify-between">
						<div class="flex flex-col">
							<span class="font-extralight">Total Carbon Produced</span>
							<span class="mt-2 font-bold">124 kg</span>
						</div>
						<div class="flex flex-col">
							<span class="font-extralight">Top Contributor</span>
							<span class="mt-2 font-bold">MPV/SUV (78 kg)</span>
						</div>
						<div class="flex flex-col mb-16">
							<span class="font-extralight">Least Contributor</span>
							<span class="mt-2 font-bold">Motorcycle (7 kg)</span>
						</div>
					</div>
					<div class="col-span-2 px-8">
						<CarbonVehicleTypeChart/>
					</div>
				</div>
				
			</div>	
			
		</div>
		
	</div>
</template>

<script>
import { onMounted, ref} from 'vue';
import { useStore } from 'vuex'
import dayjs from 'dayjs'

import CarbonVehicleTypeChart from '@/charts/CarbonVehicleTypeChart.vue'
import CarbonChart from '@/charts/CarbonChart.vue';
import CarbonGaugeChart from '@/charts/CarbonGaugeChart.vue';

export default {
	name: 'CarbonEmissionPage',
	props: [''],
	components: {
		CarbonVehicleTypeChart,
		CarbonChart,
		CarbonGaugeChart
	},
	setup(){

		return {  }
	}
}
</script>

<style>

</style>