<template>
	<div class='h-[46rem]'>
		<canvas :id='chartId' class=''></canvas>
	</div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex'
import Chart from 'chart.js/auto';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
Chart.register(MatrixController, MatrixElement);

export default {
	name: 'CongestionHighlightTimeChart',
	props: ['chartId', 'pos', 'color'],
	components: {
		
	},
	setup(props){
		const store = useStore()

		function generateData(height, width, pos){
			// pos is an array of pos
			let data = []
			let counter = 0
			for (let i = 0; i < height; i++) {
				for (let j = 0; j < width; j++) {
					let v = 0
					if (pos.includes(counter)){
						v = 1
					}
					data.push({
						x: j,
						y: i,
						v: v
					})
					counter++
				}
			}
			return data
		}

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById(props.chartId).getContext('2d');
			const chart = new Chart(ctxChart, {
				type: 'matrix',
				data: {
					datasets: [{
						label: 'Vehicle Count',
						data: generateData(12, 7, props.pos),
						backgroundColor(context) {
							if (context.dataset.data[context.dataIndex].v == 1){
								return props.color
							} else {
								// return `#f1f5f9`
								return '#e2e8f0'
							}
						},
						borderColor(context) {
							return `rgba(255, 255, 255, 0.5)`
						},
						borderWidth: 1,
						width: ({chart}) => {
							return chart.chartArea.width/7
						},
						height: ({chart}) => {
							return (chart.chartArea.height/13)
						},
					}],
				},
				options: {
					maintainAspectRatio: false,
					responsive: true,
					plugins: {
						legend: false,
						tooltip: {
							enabled: false
						}
					},
					scales: {
						x: {
							display: false,
							ticks: {
								stepSize: 1
							},
							grid: {
								display: false
							}
						},
						y: {
							display: false,
							offset: true,
							ticks: {
							stepSize: 1
							},
							grid: {
							display: false
							}
						}
					}
				},
			});
			return chart
		}
		
		onMounted(() => {
			chart = renderChart()
		})

		watch(() => [props.pos], () => {
			chart.data.datasets[0].data = generateData(12, 7, props.pos)
			chart.update()
		});
		
	}
}


</script>

<style lang='scss'>
</style>