import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import DetailsPage from '../views/DetailsPage.vue'
import VehicleDetectedPage from '../views/VehicleDetectedPage.vue'
import OverviewPage from '../views/OverviewPage.vue'
import ControlPanelPage from '../views/ControlPanelPage.vue'
import Map from '../components/Map.vue'
import SimulationPage from '../views/SimulationPage.vue'
import CarbonEmissionPage from '../views/CarbonEmissionPage.vue'
import VehicleProfilingPage from '../views/VehicleProfilingPage.vue'

const routes = [
	{
		path: '/',
		name: 'Overview',
		component: OverviewPage
	},
	{
		path: '/route/:id',
		name: 'RouteDetails',
		component: DetailsPage,
		props: true
	},
	{
		path: '/control-panel',
		name: 'ControlPanel',
		component: ControlPanelPage,
		// props: true
	},
	{
		path: '/simulation',
		name: 'Simulation',
		component: SimulationPage,
	},
	{
		path: '/carbon-emission',
		name: 'CarbonEmission',
		component: CarbonEmissionPage,
	},
	{
		path: '/vehicle-detected',
		name: 'VehicleDetected',
		component: VehicleDetectedPage
	},
	{
		path: '/vehicle-profiling',
		name: 'VehicleProfiling',
		component: VehicleProfilingPage
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/'
	}

]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router
