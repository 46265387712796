<template>
	<div class="mt-8 grid grid-cols-3 gap-x-2">
		<div class="grid grid-cols-3 gap-1 col-span-3 xl:col-span-2">
			<div class="bg-slate-200 flex flex-col">
				<div class="bg-white">
					<div class="rounded-sm text-end flex">
						<dropdown placement="bottom" :text="'Persiaran Kewajipan&nbsp;&nbsp;'" class="dropdown w-full mx-4">
							<ul class="ml-auto py-1 text-sm text-gray-700">
								<li :value='route.name' :key='route' v-for='route in routeList'>
									<a class="block py-2 px-4 hover:bg-gray-100">{{ route.name }}</a>
								</li>
							</ul>
						</dropdown>
					</div>
					<hr>
					<div class="rounded-sm text-end flex">
						<dropdown placement="bottom" :text="`Intersection ${intersection} (${intersectionLocation.find(element => element.id==intersection).name})&nbsp;&nbsp;`" class="dropdown w-full mx-4">
							<ul class="ml-auto py-1 text-sm text-gray-700">
								<li :value='index' :key='index' v-for='index in [1,2,3,4]'>
									<a @click="intersection=index" class="block py-2 px-4 hover:bg-gray-100">Intersection {{ index }} ({{intersectionLocation.find(element => element.id==index).name}})</a>
								</li>
							</ul>
						</dropdown>
					</div>
				</div>			
				<div class="bg-slate-200 p-2">
					<div v-for="(bound, index) in intersectionLocation.find(element => element.id==intersection).bound" 
						class=" bg-white rounded-sm p-4 w-full h-fit flex items-center mt-1" :class="boundActive==index?'':'bg-opacity-40'"
						@click="detectBoundActive(index)">
						<svg :class="(bound=='East')?' rotate-90': (bound=='South')?'rotate-180': (bound=='West')?'-rotate-90':''" class="stroke-indigo-700 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
						<span class="ml-2">{{bound}} Bound</span>
						<svg class=" ml-auto w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
							<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
						</svg>
					</div>
				</div>
			</div>
			<div class="col-span-2 bg-white h-[50rem] rounded-sm flex flex-col">
				<div class="bg-indigo-700 text-white text-md p-4 w-full h-fit flex items-center">
					<!-- direction -->
					<svg :class="(intersectionLocation.find(element => element.id==intersection).bound[boundActive]=='East')?'rotate-90':
						(intersectionLocation.find(element => element.id==intersection).bound[boundActive]=='South')?'rotate-180':
						(intersectionLocation.find(element => element.id==intersection).bound[boundActive]=='West')?'-rotate-90':''" 
						class="stroke-white w-8 h-8 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
					<div class="flex flex-col ml-2">
						<span class="text-lg font-bold">{{intersectionLocation.find(element => element.id==intersection).bound[boundActive]}} Bound</span>
						<span class="text-sm font-thin">Heading towards {{
							(intersectionLocation.find(element => element.id==intersection).bound[boundActive]=='North')?'Federal Highway':
							(intersectionLocation.find(element => element.id==intersection).bound[boundActive]=='South')?'Elite Highway':
							(intersectionLocation.find(element => element.id==intersection).bound[boundActive]=='East')?'Taman Subang Mewah':'Taipan USJ'
							}}</span>
						<span class="text-sm font-thin"> </span>
					</div>
				</div>
				<div class="grid grid-cols-3 gap-0.5 bg-slate-100 mt-4 mx-4">
					<div class="bg-white h-28 pt-2 pb-4 flex flex-col justify-between">
						<span>Queue</span>
						<span class="font-bold">{{queue}} Meters</span>
					</div>
					<div class="bg-white h-28 pl-4 pt-2 pb-4 flex flex-col justify-between">
						<span>Usual Queue</span>
						<span class="font-bold">{{usualQueue}} Meters</span>
					</div>
					<div class="bg-white h-28 pl-4 pt-2 pb-4 flex flex-col justify-between">
						<span>Volume</span>
						<span class="font-bold">{{volume}}</span>
					</div>
					
				</div>
				<div class="p-4">
					<span class="font-bold">Traffic Light Control</span>
					<div class="grid grid-cols-3 gap-0.5 bg-slate-100 mt-2">
						<div class="bg-white h-28 pt-2 pb-4 flex flex-col justify-between">
							<span class="text-sm">Traffic Light Timer (Current)</span>
							<span class="font-bold">{{currentTimer}} Seconds</span>
						</div>
						<div class="bg-white h-28 pl-4 pt-2 pb-4 flex flex-col justify-between">
							<span class="text-sm">Traffic Light Timer (Optimal)</span>
							<span class="font-bold">{{optimalTimer}} Seconds</span>
						</div>
						<div class="bg-white h-28 pl-4 pt-2 pb-4 flex flex-col justify-between">
							<span>Interval</span>
							<span class="font-bold">{{interval}} Seconds</span>
						</div>
					</div>
				</div>
				<div class="flex flex-col px-4 pt-2 grow">
					<div class="flex-none flex mr-3">
						<div class='px-3 py-1 bg-slate-200 rounded-sm ml-auto'>Today</div>
					</div>
					<div class="grow">
						<QueueChart class="h-full"/>
					</div>
					
				</div>
			</div>
		</div>
		<div class='hidden xl:inline col-span-0 xl:col-span-1 rounded-sm overflow-clip'>
			<Map class="class='inline-block sticky top-0 w-full'" :isControlPanelPage="true" :customCenter="customCenter" ></Map>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watchEffect, watch } from 'vue';
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { Dropdown } from 'flowbite-vue'

import RouteTFControl from '@/components/RouteTFControl.vue';
import Map from '@/components/Map.vue';
import RouteDropdown from '@/components/RouteDropdown.vue';
import TravelTimeChart from '@/charts/TravelTimeChart.vue';
import QueueChart from '@/charts/QueueChart.vue';

export default {
	name: 'ControlPanelPage',
	props: ['id'],
	components: {
		RouteDropdown,
		Map,
		QueueChart,
		TravelTimeChart,
		Dropdown
	},
	setup(){
		const store = useStore()
		const routeList = store.state.routeList
		
		const queue = ref(0)
		const usualQueue = ref(0)
		const volume = ref(0)
		const currentTimer = ref(0)
		const optimalTimer = ref(0)
		const interval = ref(0)

		const intersection = ref(1)
		const customCenter = ref({
					lat: 3.039372308841299, 
					lng: 101.58810220521231
				})
		const boundActive = ref(0)

		const intersectionLocation = [
			{
				id: 1,
				name: 'Persiaran Tujuan',
				coordinate: {
					lat: 3.039372308841299, 
					lng: 101.58810220521231
				},
				bound: [
					'North',
					'South',
					'West'
				]
			},
			{
				id: 2,
				name: 'Persiaran Mulia',
				coordinate: {
					lat: 3.0430792579541937,
					lng: 101.58809684079783
				},
				bound: [
					'North',
					'East',
					'South',
				]
			},
			{
				id: 3,
				name: 'Persiaran Perpaduan',
				coordinate: {
					lat: 3.0466790576151492, 
					lng: 101.5891603366263 
				},
				bound: [
					'North',
					'South',
					'West'
				]
			},
			{
				id: 4,
				name: 'Jalan Kewajipan',
				coordinate: {
					lat: 3.0488110761658183, 
					lng: 101.5910432474246
				},
				bound: [
					'North',
					'East',
					'South',
				]
			}
		]
		
		function generateRandomInRange(min, max) {
			min = Math.ceil(min);
			max = Math.floor(max);
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}

		function detectBoundActive(indexActive=null){
			if (indexActive == null){
				boundActive.value = 0
			} else {
				boundActive.value = indexActive
			}
		}

		onMounted(() => {
			queue.value = generateRandomInRange(40, 50)
			usualQueue.value = generateRandomInRange(35, 45)
			volume.value = generateRandomInRange(200, 400)
			currentTimer.value = generateRandomInRange(3, 8) * 5
			optimalTimer.value = currentTimer.value + generateRandomInRange(1, 3) * 5
			interval.value = generateRandomInRange(8, 16) * 5

			detectBoundActive()
		})

		watch(intersection, () => {
			queue.value = generateRandomInRange(40, 50)
			usualQueue.value = generateRandomInRange(35, 45)
			volume.value = generateRandomInRange(200, 400)
			currentTimer.value = generateRandomInRange(3, 8) * 5
			optimalTimer.value = currentTimer.value + generateRandomInRange(1, 3) * 5
			interval.value = generateRandomInRange(8, 16) * 5

			detectBoundActive()
			customCenter.value = intersectionLocation.find(element => element.id == intersection.value).coordinate
		})

		watch(boundActive, () => {
			console.log(boundActive.value)
			queue.value = generateRandomInRange(40, 50)
			usualQueue.value = generateRandomInRange(35, 45)
			volume.value = generateRandomInRange(200, 400)
			currentTimer.value = generateRandomInRange(3, 8) * 5
			optimalTimer.value = currentTimer.value + generateRandomInRange(1, 3) * 5
			interval.value = generateRandomInRange(8, 16) * 5
		},{deep: true})

		return { routeList, intersection, customCenter, queue, intersectionLocation,
					usualQueue, volume, currentTimer, optimalTimer, interval, boundActive, detectBoundActive}
	}
}
</script>

<style>

</style>