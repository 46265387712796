<template>
	<div class="mt-8">
		<div class="p-4 bg-white">
			<span class="text-xl">Vehicle Profiling</span>
		</div>
		<div class="bg-white py-4 mt-1 rounded-sm">
			<div class='h-96 grid grid-cols-5 divide-x-2 divide-slate-100 '>
				<Score header='Vehicle In' scoreTextSize='text-6xl' :score='`${generateRandomInRange(1000, 1100)}`'  :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
					:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='false' />
				<div class='col-span-3 grid grid-rows-2 divide-y-2 divide-slate-100'>
					<div class='grid grid-cols-3'>
						<Score header='Resident Vehicle' :score='`${generateRandomInRange(700, 750)}`'  :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
						:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='true' />
						<Score header='Tourist Vehicle' :score='`${generateRandomInRange(600, 650)}`'  :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
						:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='true' />
						<Score header='Hottest Spot' :score='`Penang Hill`'  />
					</div>
					<div class='grid grid-cols-3'>
						<Score header='Top Tourist Origin' :score='`Selangor`'  :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
						:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='true' />
						<Score header='Congestion Level' :score='`${generateRandomInRange(70, 85)}%`'  :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
						:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='true' />
						<Score header='Congestion Level' :score='`${generateRandomInRange(70, 85)}%`'  :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
						:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='true' />
					</div>
				</div>
				<Score header='Vehicle Out' scoreTextSize='text-6xl' :score='`${generateRandomInRange(700, 800)}`'  :trend='Math.round(Math.abs(generateRandomInRange(70, 85) - generateRandomInRange(70, 85))/generateRandomInRange(70, 85)*100*10)/10 + "%"' 
				:isUp='(generateRandomInRange(70, 85) - generateRandomInRange(70, 85)) > 0' :isUpEqualBad='true' />
			</div>
		</div>
		<div class="grid grid-cols-2 mt-1 gap-x-1 ">
			<div class="h-96 p-4 bg-white rounded-sm">
				<span class="font-bold text-2xl">Tourist Vehicle In</span>
				<VehicleInChart class="w-full mb-4 mt-6"/>
			</div>
			<div class="h-96 p-4 bg-white rounded-sm">
				<span class="font-bold text-2xl">Tourist Vehicle Out</span>
				<VehicleOutChart class="w-full mb-4 mt-6"/>
			</div>
		</div>
		<div class="bg-white mt-1 p-4">
			<div class="grid grid-cols-4">
				<div class="flex flex-col col-span-3">
					<span class="font-bold text-2xl pb-4">Vehicle Origin Distribution by Destination</span>
					<div class="grid grid-cols-3 gap-x-4 mt-4 h-20 w-full">
						<div class="flex flex-col justify-between">
							<span class="font-extralight">Top Origin</span>
							<span class="font-bold text-2xl">Selangor (37%)</span>
						</div>
						<div class="flex flex-col justify-between">
							<span class="font-extralight">Bottom Origin</span>
							<span class="font-bold text-2xl">Perlis (3%)</span>
						</div>
					</div>
				</div>
				<dropdown placement="bottom" :text="`${chosenDestination}&nbsp;&nbsp;`" class="dropdown w-1/2 route-dropdown ml-auto mb-auto -mt-2">
					<ul class="ml-auto py-1 text-sm text-gray-700">
						<li :value='destination' :key='destination' v-for='destination in destinationList'>
							<a @click="chosenDestination=destination" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{ destination }}</a>
						</li>
					</ul>
				</dropdown>
				
			</div>		
			<div class="mt-4 bg-white ">
				<div class="py-2 grid grid-cols-4">
					
					<div class="col-span-1 h-96">
						<StateOriginMapChart class="ml-10 w-full h-full"/>
					</div>
					<div class="h-96 col-span-3">
						<VehicleDestinationSankeyChart :destination="chosenDestination"/>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white mt-1 p-4">
			<div class="grid grid-cols-4">
				<div class="flex flex-col col-span-3">
					<span class="font-bold text-2xl pb-4">Vehicle Destination Distribution by Origin</span>
					<div class="grid grid-cols-3 gap-x-4 mt-4 h-20 w-full">
						<div class="flex flex-col justify-between">
							<span class="font-extralight">Top Visited</span>
							<span class="font-bold text-2xl">Clan Jetties (78%)</span>
						</div>
						<div class="flex flex-col justify-between">
							<span class="font-extralight">Least Visited</span>
							<span class="font-bold text-xl">Historical Streets of Penang (6%)</span>
						</div>
					</div>
				</div>
				<dropdown placement="bottom" :text="`${chosenOrigin}&nbsp;&nbsp;`" class="dropdown w-1/2 route-dropdown ml-auto mb-auto -mt-2">
					<ul class="ml-auto py-1 text-sm text-gray-700">
						<li :value='origin' :key='origin' v-for='origin in originList'>
							<a @click="chosenOrigin=origin" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{ origin }}</a>
						</li>
					</ul>
				</dropdown>
				
			</div>		
			<div class="mt-4 bg-white ">
				<div class="py-2">
					<VehicleOriginChart class="h-96" />
					<!-- <VehicleDestinationSankeyChart class="w-full h-96" :destination="chosenDestination"/> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watchEffect, watch } from 'vue';
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { Dropdown } from 'flowbite-vue'

import Score from '@/components/Score.vue';
import VehicleInChart from '@/charts/VehicleInChart.vue';
import VehicleOutChart from '@/charts/VehicleOutChart.vue';
import VehicleDestinationSankeyChart from '@/charts/VehicleDestinationSankeyChart.vue';
import VehicleOriginChart from '@/charts/VehicleOriginChart.vue';
import StateOriginMapChart from '@/charts/StateOriginMapChart.vue'

export default {
	name: 'VehicleProfilingPage',
	components: {
		Dropdown,
		Score,
		VehicleInChart,
		VehicleOutChart,
		VehicleDestinationSankeyChart,
		VehicleOriginChart,
		StateOriginMapChart
	},
	setup(){
		const destinationList = [
			'Penang Hill',
			'Clan Jetties',
			'Penang Butterfly Farm',
			'Historical Streets of George Town'
		]
		const chosenDestination = ref(destinationList[0])

		const originList = [
			'Selangor/KL',
			'Perak',
			'Johor',
			'Terengganu'
		]
		const chosenOrigin = ref(originList[0])

		function generateRandomInRange(min, max) {
			min = Math.ceil(min);
			max = Math.floor(max);
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}

		return { generateRandomInRange, destinationList, chosenDestination, originList, chosenOrigin }
	}
}
</script>

<style>

</style>