<template>
	<canvas id='vehicleDestinationSankeyChart' class=''></canvas>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';
import {SankeyController, Flow} from 'chartjs-chart-sankey';

Chart.register(SankeyController, Flow);

export default {
	name: 'VehicleDestinationSankeyChart',
	props: ['destination'],
	components: {
		
	},
	setup(props){
		const store = useStore()
		const colors = {
			a: 'rgba(153, 102, 255, 1)',
			b: 'rgba(153, 102, 255, 1)',
			c: 'rgba(75, 192, 192, 1)',
			d: 'rgba(171, 224, 47, 1)'
		}

		const getColor = (key) => {
			if (['a', 'b', 'c', 'd'].includes(key)){
				return colors[key]
			} else {
				return 'rgba(54, 162, 235, 1)'
			}
		}

		function generateRandomInRange(min, max) {
			min = Math.ceil(min);
			max = Math.floor(max);
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('vehicleDestinationSankeyChart').getContext('2d');

			const chart = new Chart(ctxChart, {
				type: 'sankey',
				data: {
					datasets: [{
						data: [
							//from origin state to bridge
							{from: 'Selangor/KL', to: 'a', flow: 300},
							{from: 'Selangor/KL', to: 'b', flow: 400},
							{from: 'Perak', to: 'a', flow: 100},
							{from: 'Perak', to: 'b', flow: 150},
							{from: 'Kelantan', to: 'a', flow: 100},
							{from: 'Kelantan', to: 'b', flow: 50},
							{from: 'Terengganu', to: 'a', flow: 50},
							{from: 'Terengganu', to: 'b', flow: 200},
							{from: 'Johor', to: 'a', flow: 150},
							{from: 'Johor', to: 'b', flow: 50},
							{from: 'Pahang', to: 'a', flow: 250},
							{from: 'Pahang', to: 'b', flow: 100},
							{from: 'Perlis', to: 'a', flow: 50},
							{from: 'Perlis', to: 'b', flow: 50},
							{from: 'Negeri Sembilan', to: 'a', flow: 100},
							{from: 'Negeri Sembilan', to: 'b', flow: 100},
							{from: 'Melaka', to: 'a', flow: 90},
							{from: 'Melaka', to: 'b', flow: 10},

							// from origin to center
							{from: 'a', to: 'c', flow: 1190},
							{from: 'b', to: 'c', flow: 1110},

							//from center to destination
							{from: 'c', to: 'd', flow: 2300},
						],
						colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
						colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
						colorMode: 'gradient',
						priority: {
							// a: 1,
							// b: 1
						},
						labels: {
							a: '1st Penang Bridge',
							b: '2nd Penang Bridge',
							c: 'Pulau Pinang',
							d: props.destination,
						},
						},
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
				}
			});
			return chart	
		}
		onMounted(() => {
			chart = renderChart()
		})

		watch(() => props.destination, () => {
			chart.data.datasets[0].labels.d = props.destination
			chart.update()
		})
	}
}
</script>

<style lang='scss'>

</style>