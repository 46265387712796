<template>
	<canvas id='carbonChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'

export default {
	name: 'CarbonChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		const months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		]

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('carbonChart').getContext('2d')
			const carbonGoal = {
				id: 'carbonGoal',
				beforeDraw(chart, args, options){
					const { ctx, chartArea: { top, right, bottom, left, width, height },
						scales: { x, y }} = chart
					ctx.save()

					ctx.strokeStyle = 'black'
					ctx.setLineDash([10, 10])
					ctx.strokeRect(left, y.getPixelForValue(100), width, 0)
					ctx.restore()

					// ctx.fillStyle = 'rgba(255, 99, 132, 0.2)'
					// ctx.fillRect(left, top, width, y.getPixelForValue(14000)-top)
					// ctx.restore()

					ctx.font = '25px'
					ctx.fillStyle = 'black'
					ctx.fillText('Reduction Goal', left+5, y.getPixelForValue(100)+20)
				}
			}

			const chart = new Chart(ctxChart, {
				type: 'line',
				data: {
					labels: months,
					datasets: [{
						label: 'dd',
						data: [120, 125, 112, 138, 109, 119, 120, 124, 192, 113, 115, 121],
						backgroundColor: 'rgba(37, 99, 235, 0.2)',
						borderColor: 'rgba(255, 99, 132, 0.2))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 2,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					}]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
					},
					plugins: {
						legend: false,
						tooltip: {
							displayColors: true,
							callbacks: {
								title(context){
									return `${context[0].label}, 2022`
								},
								label(context){
									const dataset = context.dataset.label
									const v = context.dataset.data[context.dataIndex];
									return ` ${v} KG CO2`
								}
							}
						}
					},
					
					scales: {
						x: {
							
						},
						y:{
							beginAtZero: true,
							suggestedMax: 150,
							ticks: {
								maxTicksLimit: 1
							},
							title: {
								display: true,
								text: 'CO2 Emission (KG)'
							},
							ticks: {
								stepSize:40,
								beginAtZero: true,
							}
						}
					}
				},
				plugins: [carbonGoal]
			})
			return chart
		}

		onMounted(() => {
			chart = renderChart()
		})
	}
}
</script>

<style lang='scss'>

</style>