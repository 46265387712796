/*global google*/
<template>
	<div v-if='isDetailsPage' class=''>
		<GMapMap
		ref="myMapRef"
		:center="center"
		:zoom="14"
		style="height: 100vw"
		:disableDefaultUI="true"
		:options='options'
		>
			<GMapPolyline 
				:path="path"
				:options="{strokeColor:'#0000FF'}" 
				ref="polyline" />
			<GMapMarker
				:key="index"
				v-for="(position, index) in persiaranKewajipanCameras"
				:position="position"
				:icon='{
					url: "https://user-images.githubusercontent.com/64152220/191068864-85e1f72f-1cff-4e8b-ae86-7102d369e28b.png",
					// url: m.icon
					scaledSize: {width: 25, height: 25},
					// labelOrigin: {x: 16, y: -10}
				}'
			/>
			<GMapMarker
				:key="index"
				v-for="(position, index) in otherCameras"
				:position="position"
				:icon='{
					url: "https://user-images.githubusercontent.com/64152220/192212274-e291fb69-4ba3-41ff-b358-104e520c6172.png",
					// url: m.icon
					scaledSize: {width: 25, height: 25},
					// labelOrigin: {x: 16, y: -10}
				}'
			/>
		</GMapMap>
	</div>
	<div v-else-if='isDetectedVehiclePage' class=''>
		<GMapMap ref="myMapRef"
		:center="center"
		:zoom="15"
		style="height: 50rem"
		:disableDefaultUI="true"
		:options='options'
		>	
			<GMapMarker
				:key="index"
				v-for="(m, index) in cctvMarkers"
				:position="m.position"
				:icon='{
					// url: "https://user-images.githubusercontent.com/64152220/190277144-2c143d0c-1bc4-4db6-88ed-b01f62e2b0f8.png",
					url: m.imageUrl,
					// url: m.icon
					scaledSize: {width: 35, height: 35},
					// labelOrigin: {x: 16, y: -10}
				}'
			/>
			
		</GMapMap>
	</div>
	<div v-else-if='isControlPanelPage' class=''>
		<GMapMap
		ref="myMapRef"
		:center="customCenter"
		:zoom="18"
		style="height: 50rem"
		:disableDefaultUI="true"
		:options='options'
		>
			<GMapMarker
				:key="index"
				v-for="(position, index) in persiaranKewajipanCameras"
				:position="position"
				:icon='{
					url: "https://user-images.githubusercontent.com/64152220/191068864-85e1f72f-1cff-4e8b-ae86-7102d369e28b.png",
					// url: m.icon
					scaledSize: {width: 25, height: 25},
					// labelOrigin: {x: 16, y: -10}
				}'
			/>
			<GMapMarker
				:key="index"
				v-for="(position, index) in otherCameras"
				:position="position"
				:icon='{
					url: "https://user-images.githubusercontent.com/64152220/192212274-e291fb69-4ba3-41ff-b358-104e520c6172.png",
					// url: m.icon
					scaledSize: {width: 25, height: 25},
					// labelOrigin: {x: 16, y: -10}
				}'
			/>
		</GMapMap>
	</div>
	<div v-else class=''>
		<GMapMap ref="myMapRef"
		:center="center"
		:zoom="14"
		style="height: 58rem"
		:disableDefaultUI="true"
		:options='options'
		>
			<GMapMarker
				:key="index"
				v-for="(position, index) in persiaranKewajipanCameras"
				:position="position"
				:icon='{
					url: "https://user-images.githubusercontent.com/64152220/191068864-85e1f72f-1cff-4e8b-ae86-7102d369e28b.png",
					// url: m.icon
					scaledSize: {width: 25, height: 25},
					// labelOrigin: {x: 16, y: -10}
				}'
			/>
			<GMapMarker
				:key="index"
				v-for="(position, index) in otherCameras"
				:position="position"
				:icon='{
					url: "https://user-images.githubusercontent.com/64152220/192212274-e291fb69-4ba3-41ff-b358-104e520c6172.png",
					// url: m.icon
					scaledSize: {width: 25, height: 25},
					// labelOrigin: {x: 16, y: -10}
				}'
			/>
		</GMapMap>
	</div>
	
</template>


<script>
import { ref, onMounted } from 'vue';

export default {
	name: 'Map',
	props: {
		isDetailsPage: {
			type: Boolean,
			required: false,
			default: false
		},
		isDetectedVehiclePage: {
			type: Boolean,
			required: false,
			default: false
		},
		isControlPanelPage: {
			type: Boolean,
			required: false,
			default: false
		},
		path: {
			type: Object,
			required: false,
			default:  [{lat: 3.0276825088366187, lng:  101.58483221970356}]
		},
		customCenter: {
			type: Object,
			required: false,
			default:  {lat: 3.0566778249687783, lng:  101.58508492823987}
		},
	},
	setup(props){
		const center = ref({lat: 3.0566778249687783, lng:  101.58508492823987})
		if (props.isDetailsPage){
			center.value = {lat: 3.0076825088366187, lng:  101.58483221970356}
		}

		const options = {
			//id is for gray map style (got from the itms-dashboard gcp project)
			mapId: "9c27a2b9ec7422a2"
		}

		const myMapRef = ref(null)

		onMounted(() => {
			myMapRef.value.$mapPromise.then((map) => {
				const trafficLayer = new window.google.maps.TrafficLayer()
				trafficLayer.setMap(map)
			})
		})
		const otherCameras = [ 
			{ lat: 3.0805259322434275, lng: 101.58448561686735 },
			{ lat: 3.073455109251071, lng: 101.6106639761993 },
			{ lat: 3.0728123048267006, lng: 101.58590182309209 },
			{ lat: 3.0785975306111477, lng: 101.57899245303264 },
			{ lat: 3.0655271611009063, lng: 101.6109643835932 }, 
			{ lat: 3.050485324777059, lng: 101.60212382277004 },
			{ lat: 3.043200029313489, lng: 101.60130843128363 },
			{ lat: 3.0431143196625254, lng: 101.5940557384883 },
			{ lat: 3.066169969788581, lng: 101.58611639982665 }, 
			{ lat: 3.080654492260875, lng: 101.58435687072324 },
			{ lat: 3.0578991336860164, lng: 101.60461291259833 },
			{ lat: 3.0467998285209226, lng: 101.57006606167346 },
			{ lat: 3.0588419263295643, lng: 101.57148226789582 },
			{ lat: 3.0659985539980754, lng: 101.57997950573719 },
			{ lat: 3.0702410831901035, lng: 101.58096655876285 },	
			{ lat: 3.0576420079665416, lng: 101.57800540010055 },
			{ lat: 3.0451284946159447, lng: 101.57555922560745 },
			{ lat: 3.0573420281876014, lng: 101.5784345535204 }, 
			{ lat: 3.039385943094575, lng: 101.58285483351467 },
			{ lat: 3.0449570755463005, lng: 101.57577380220911 }, 
			{ lat: 3.0821972108526428, lng: 101.59319743117196 },
			{ lat: 3.067028404476639, lng: 101.59302844192904 },
			{ lat: 3.076713335905827, lng: 101.59320010330377 },
			{ lat: 3.07264089014299, lng: 101.5929399391764 },  
			{ lat: 3.078940357798884, lng: 101.59328326201016 },	
			{ lat: 3.050605611113801, lng: 101.58354245008829 },
			{ lat: 3.051355565108707, lng: 101.58322058502449 },	
			{ lat: 3.066654512341395, lng: 101.5930696557971 },
			{ lat: 3.0702113790799, lng: 101.5930696557971 },

		]

		const persiaranKewajipanCameras = [
			{ lat: 3.03055, lng: 101.58281 },
			{ lat: 3.03057, lng: 101.58281 },
			{ lat: 3.03128, lng: 101.58726 },
			{ lat: 3.03306, lng: 101.58798 },
			{ lat: 3.0332, lng: 101.58828 },
			{ lat: 3.03636, lng: 101.58799 },
			{ lat: 3.04498, lng: 101.58835 },
			{ lat: 3.05715162047605, lng: 101.59293018134738 },
			{ lat: 3.057890856339734, lng: 101.594110353248 },
			{ lat: 3.0579122834586046, lng: 101.59483991405929 },
			{ lat: 3.0286721461127155, lng: 101.58038720121077 },
			{ lat: 3.031543453966457, lng: 101.58718928297611 },
			{ lat: 3.028500724375921, lng: 101.58036574364009 },
			{ lat: 3.0339004921506256, lng: 101.58821925118373 },
			{ lat: 3.039343087953175, lng: 101.58800467447381 }, 
			{ lat: 3.043028609895179, lng: 101.58804759001656 },
			{ lat: 3.0465855549996386, lng: 101.58903464293856 },
			{ lat: 3.0566563598064658, lng: 101.59182414021943 },
			{ lat: 3.0622274028401653, lng: 101.59263953180728 },  
			{ lat: 3.050872372438743, lng: 101.59195555832306 },
			{ lat: 3.0487711419613115, lng: 101.59112140136295 }, 
		]

		const cctvMarkers = ref([
			{	
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192227719-d0374ee1-2e9b-407f-b740-e65e63460127.png',
				position: {
					lat: 3.0569471224030895, lng: 101.59183143448483
				},
			},{
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192228487-7874bba6-b86c-49d2-96ad-5886dbc3bf8a.png',
				position: {
					lat: 3.0505350321679563, lng: 101.5829318649444
				},
			},{
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192228488-d9c91108-ecaa-4287-88e0-8f3dedab5099.png',
				position: {
					lat: 3.0574560167531444, lng: 101.57823263501287
				},
			},{
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192228489-8a6f9c46-7cd4-4e23-980a-d4b7ca43a7ee.png',
				position: {
					lat: 3.063841281978014, lng: 101.58623634614374
				},
			},{
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192228494-a5a9d2e1-7ea6-4f4a-a3bb-9f15060b0f24.png',
				position: {
					lat: 3.046699544774097, lng: 101.58898292800235
				},
			},{
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192228496-210a091a-0d3c-46ff-a7a4-bdefa996aff9.png',
				position: {
					lat: 3.058227593186204, lng: 101.58512054723671
				},
			},{
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192228503-e0d6d21b-4507-496b-ad10-12487e4e7024.png',
				position: {
					lat: 3.06246995296762, lng: 101.59284530876799
				},
			},{
				imageUrl: 'https://user-images.githubusercontent.com/64152220/192228483-2b7a7c67-4fdd-4a16-b8b1-1330d58ceae0.png',
				position: {
					lat: 3.0678266964500702, lng: 101.58022819826688
				},
			}
		])

		return { center, options, myMapRef, cctvMarkers, persiaranKewajipanCameras, otherCameras }
	}
}
</script>