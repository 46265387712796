<template>
	<canvas id='overspeedingChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs'

export default {
	name: 'OverspeedingChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('overspeedingChart').getContext('2d');

			const chart = new Chart(ctxChart, {
				type: 'bar',
				data: {
					datasets: [{
						label: 'Vehicle Within Speed Limit',
						data: [],
						backgroundColor: 'rgba(75, 192, 192, 0.5)',
						borderColor: 'rgba(75, 192, 192, 0.8)',
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0
					},{
						label: 'Vehicle Over Speed Limit',
						data: [],
						backgroundColor: 'rgba(255, 99, 132, 0.5)',
						borderColor: 'rgba(255, 99, 132, 0.8)',
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0
					}]
				},
				options: {
					responsive: true,
					categoryPercentage: 0.7,
					barPercentage: 1,
					plugins: {
						tooltip: {
							displayColors: true,
							callbacks: {
								title(context){
									const datetime_obj = dayjs(context[0].raw.x)
									if (store.state.detailsData.is_daily_data){
										return datetime_obj.format('D MMM YYYY')
									} else {
										return datetime_obj.format('D MMM YYYY, h A')
									}
								},
								afterBody: function(items) {
									const a = items[0].parsed._stacks.y[0]
									const b = items[0].parsed._stacks.y[1]
									return `Total Vehicles: ${a + b}`
								}
							}
						},
					},
					scales: {
						x: {
							type: 'time',
							time: {
								unit: 'hour'
							},
							stacked: true,
							offset: true,
							title: {
								display: true,
								text: 'Time'
							},
							// grid:{
							// 	drawOnChartArea: false
							// },
							ticks: {
								// align: 'start',
								autoSkip: true,
								callback: function(value, index, ticks){
									if (value.includes('AM') || value.includes('PM')){
										const result = [value.slice(0, -2), value.slice(-2)];
										return result[0] + ' ' + result[1]
									} else {
										// check if year, remove year from the value (May 2022 -> May)
										if (parseInt(value.split(' ')[1]) > 100) {
											return value.split(' ')[0]
										} else {
											return value
										}
									}	
								}
								// labelOffset: 0,
								// maxTicksLimit: 9,
								// font: {
								// 	size: 12
								// }
							}
						},
						y:{
							stacked: true,
							beginAtZero: true,
							title: {
								display: true,
								text: 'Number of Vehicles'
							},
							ticks: {
								beginAtZero: true,
							}
						}
					}
				},
			});
			return chart
		}

		onMounted(() => {
			chart = renderChart()
		})

		store.watch((state, getters) => [state.detailsData],  () => {
			chart.data.datasets[0].data = store.state.detailsData.data.within_speed_limit_vehicle_count
			chart.data.datasets[1].data = store.state.detailsData.data.overspeeding_vehicle_count
			if (store.state.detailsData.is_daily_data){
				if (store.state.detailsData.num_day > 120){
					chart.options.scales.x.time.unit = 'month'
					// set to no limit actually
					chart.options.scales.x.ticks.maxTicksLimit = 100
				} else if (store.state.detailsData.num_day > 25){
					chart.options.scales.x.time.unit = 'day'
					chart.options.scales.x.ticks.maxTicksLimit = 14
				} else {
					chart.options.scales.x.time.unit = 'day'
					chart.options.scales.x.ticks.maxTicksLimit = 9
				}
				
			} else {
				chart.options.scales.x.time.unit = 'hour'
				chart.options.scales.x.ticks.maxTicksLimit = 9
			}
			chart.update()
		})

	}
}
</script>

<style lang='scss'>

</style>