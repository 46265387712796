<template>
	<canvas id='vehicleOriginChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'

export default {
	name: 'VehicleOriginChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		const data = [45, 78, 65, 6, 67, 43, 65, 34]
		const label = ['Penang Hill',
						'Clan Jetties',
						'Penang Butterfly Farm',
						'Historical Streets of George Town',
						'Pinang Peranakan Mansion',
						'Fort Cornwallis',
						'Kek Lok Si Temple',
						'Batu Ferringhi Night Market']

		

		function sortDataAndLabels(label, data){
			const arrayOfObj = label.map(function(d, i) {
				return {
					label: d,
					data: data[i] || 0
				};
			});

			const sortedArrayOfObj = arrayOfObj.sort(function(a, b) {
				return b.data-a.data
			});

			let sortedLabel = []
			let sortedData = []
			sortedArrayOfObj.forEach(function(d){
				sortedLabel.push(d.label);
				sortedData.push(d.data);
			});

			return {sortedLabel, sortedData}
		}
		

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('vehicleOriginChart').getContext('2d')

			const chart = new Chart(ctxChart, {
				type: 'bar',
				data: {
					labels: [],
					datasets: [{
						label: 'Visit Percentage',
						data: [],
						backgroundColor: [
							'rgba(255, 99, 132, 0.5)',
							'rgba(54, 162, 235, 0.5)',
							'rgba(75, 192, 192, 0.5)',
							'rgba(153, 102, 255, 0.5)',
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
						],
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 1,
						barPercentage: 0.8,
						categoryPercentage: 1.0,
					}]
				},
				options: {
					indexAxis: 'y',
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: false,
						tooltip: {
							displayColors: true,
							callbacks: {
								// title(context){
								// 	return `${context[0].label}, 2022`
								// },
								label(context){
									const dataset = context.dataset.label
									const v = context.dataset.data[context.dataIndex];
									return `${dataset}: ${v}%`
								}
							}
						}
					},
					
					scales: {
						x: {
							suggestedMax:100,
							ticks: {
							// Include a dollar sign in the ticks
							callback: function(value, index, ticks) {
								return value+'%'
							}
						},
						y:{
							beginAtZero: true,
							// suggestedMax: 100,
							grid: {
								display: false
							},
							
						}
						}
					}
				},
			})
			return chart
		}

		onMounted(() => {
			chart = renderChart()
			const {sortedLabel, sortedData} = sortDataAndLabels(label, data)
			chart.data.labels = sortedLabel
			chart.data.datasets[0].data = sortedData
			chart.update()
			// console.log(sortedLabel, sortedData)
		})
	}
}
</script>

<style lang='scss'>

</style>