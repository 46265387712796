<template>
	<canvas id='timeSavedChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';

export default {
	name: 'TimeSavedChart',
	components: {
		
	},
	setup(){
		const store = useStore()

		const hours = [
				'12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
				'6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
				'12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
				'6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
				'12 AM',
			]

		let data1 = [
			1, 2, 1, 3, 2, 2, 6, 5, 4, 6, 4, 5, 
			2, 3, 1, 2, 4, 5, 6, 4, 1, 2, 3, 1, 1
		]


		function limitQueueData(data){
			const currentHour = dayjs().hour()
			for (let i = currentHour; i < data.length; i++) {
				data[i] = 0
			}
			return data
		}

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('timeSavedChart').getContext('2d');

			const chart = new Chart(ctxChart, {
				type: 'bar',
				data: {
					labels: hours,
					datasets: [{
						label: 'AI',
						data: data1,
						backgroundColor: 'rgba(37,99,235, 0.4)',
						borderColor: 'rgba(255, 99, 132, 0.1))',
						// borderDash: [5, 5],
						fill: true,
						borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
						pointRadius: 0,
						lineTension: 0.1
					},
				]
				},
				options: {
					// legend:{
					// 	display: false
					// },
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
					},
					plugins: {
						legend: false,
						// title:{
						// 	display: true,
						// 	text: 'Average Travel Time',
						// 	padding: 4
						// },
						tooltip: {
							callbacks: {
								label(context){
									const dataset = context.dataset.label
									const v = context.dataset.data[context.dataIndex];
									return `${dataset}: ${v} minutes`
								}
							}
						}
					},
					responsive: true,
					scales: {
						x: {
							ticks: {
								maxTicksLimit: 12
							},
							title: {
								display: true,
								text: 'Time'
							},
						},
						y: {
							beginAtZero: true,
							suggestedMax: 5,
							grace: 2,
							title: {
								display: true,
								text: 'Minutes'
							},
							ticks: {
								maxTicksLimit: 10,
								callback: (value, index, ticks) => {
									return value
								}
							}
						}
					},
				},
			});
			return chart
		}

		onMounted(() => {
			chart = renderChart()
		})
	}
}
</script>

<style lang='scss'>

</style>