<template>
	<div :class='bgColor, borderColor' class=' p-4 border-2 rounded-sm bg-opacity-20'>
		<div class=''>
			<span>{{ text }}</span>
		</div>
		
	</div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
	name: 'InfoBox',
	props: ['text', 'status'],
	setup(props){
		const bgColor = ref('bg-sky-400')
		const borderColor = ref('border-sky-200')

		onMounted(() => {
			if (props.status=='warning'){
				bgColor.value = 'bg-rose-400'
				borderColor.value = 'border-red-200'
			} else if (props.status=='success'){
				bgColor.value = 'bg-green-400'
				borderColor.value = 'border-green-200'
			} else if (props.status=='info'){
				bgColor.value = 'bg-sky-400'
				borderColor.value = 'border-sky-200'
			}
		})

		return { bgColor, borderColor }
	}
}
</script>