<template>
	<div class="flex flex-col">
		<div class='flex justify-between'>
			<div>
				<input v-model='startDate' :max='checkEndLimit()' class='border border-transparent' type='date' name='startDate' id='startDate'>
				<span class='mx-4 text-gray-500' >to</span>
				<input v-model='endDate' :max='dayjs().format("YYYY-MM-DD")' class='border border-transparent' type='date' name='endDate' id='endDate'>
			</div>
			<div class='flex flex-row-reverse'>
				<button @click='setDateRange("today")'  :class='$store.state.rangeActive[0]?"bg-slate-200":"bg-white"' class=' px-3 py-1 hover:bg-slate-300 shadow-sm text-slate-800 rounded-sm ml-2'>
					Today
				</button>
				<button  @click='setDateRange("this week")' :class='$store.state.rangeActive[1]?"bg-slate-200":"bg-white"' class=' px-3 py-1  hover:bg-slate-300 shadow-sm text-slate-800 rounded-sm ml-2'>
					This Week
				</button>
				<button @click='setDateRange("this month")' :class='$store.state.rangeActive[2]?"bg-slate-200":"bg-white"' class=' px-3 py-1 hover:bg-slate-300 shadow-sm text-slate-800 rounded-sm ml-2'>
					This Month
				</button>
				<button @click='setDateRange("this year")' :class='$store.state.rangeActive[3]?"bg-slate-200":"bg-white"' class=' px-3 py-1 hover:bg-slate-300 shadow-sm text-slate-800 rounded-sm ml-2'>
					This Year
				</button>
			</div>
		</div>
	</div>
	
</template>

<script>
import { onMounted, ref, watch, defineComponent } from 'vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import * as dayOfYear  from 'dayjs/plugin/dayOfYear'
// import 'dayjs/plugin/dayOfYear' 

export default defineComponent ({
	name: 'TimeFrameButtons',
	setup(props, {emit}){
		const store = useStore()
		dayjs.extend(dayOfYear)
		// use local var for the date, every changes will reflect to vuex var. 
		const startDate = ref(store.state.startDate)
		const endDate = ref(store.state.endDate)
		const hideGlobalChart = ref(true)

		// [today, this week, this month, this year]
		// const rangeActive = ref([true, false, false, false])
		
		function checkEndLimit(){
			if (dayjs().isAfter(dayjs(endDate.value))){
				if (dayjs(startDate.value).isAfter(dayjs(endDate.value))){
					startDate.value = endDate.value
				}
				return endDate.value
			} else {
				return dayjs().format('YYYY-MM-DD')
			}
		}
		
		function setDateRange(range){
			if (range=='today'){
				startDate.value = dayjs().format('YYYY-MM-DD')
				endDate.value = dayjs().format('YYYY-MM-DD')
			} else if (range=='this week'){
				const dayOfWeek = dayjs().day()
				startDate.value = dayjs().subtract(dayOfWeek, 'day').format('YYYY-MM-DD')
				endDate.value = dayjs().format('YYYY-MM-DD')
			} else if (range=='this month'){
				const dayOfMonth = dayjs().date() - 1
				startDate.value = dayjs().subtract(dayOfMonth, 'day').format('YYYY-MM-DD')
				endDate.value = dayjs().format('YYYY-MM-DD')
			} else if (range=='this year'){
				const dayYear = dayjs().dayOfYear() - 1
				startDate.value = dayjs().subtract(dayYear, 'day').format('YYYY-MM-DD')
				endDate.value = dayjs().format('YYYY-MM-DD')
			}
		}

		watch([ startDate, endDate ], () => {
			const dayOfWeek = dayjs().day()
			const dayOfMonth = dayjs().date() - 1
			const dayYear = dayjs().dayOfYear() - 1

			if (startDate.value == dayjs().format('YYYY-MM-DD') && endDate.value == dayjs().format('YYYY-MM-DD')){
				store.commit('setRangeActive', [true, false, false, false])
			} else if (startDate.value == dayjs().subtract(dayOfWeek, 'day').format('YYYY-MM-DD') && endDate.value == dayjs().format('YYYY-MM-DD')) {
				store.commit('setRangeActive', [false, true, false, false])
			} else if (startDate.value == dayjs().subtract(dayOfMonth, 'day').format('YYYY-MM-DD') && endDate.value == dayjs().format('YYYY-MM-DD')) {
				store.commit('setRangeActive', [false, false, true, false])
			} else if (startDate.value == dayjs().subtract(dayYear, 'day').format('YYYY-MM-DD') && endDate.value == dayjs().format('YYYY-MM-DD')) {
				store.commit('setRangeActive', [false, false, false, true])
			} else {
				store.commit('setRangeActive', [false, false, false, false])
			}
		})

		watch(startDate, () => {
			store.commit('setStartDate', startDate.value)
		})

		watch(endDate, () => {
			store.commit('setEndDate', endDate.value)
		})

		return { dayjs, startDate, endDate, checkEndLimit, setDateRange, hideGlobalChart }
	}
})

</script>