<template>
	<div class='w-full h-[54rem] bg-white py-4 px-8 flex flex-col'>
		<div class='mt-4 mb-2 flex justify-between'>
			<div>
				<span class='text-2xl font-black text-black'>Subang Jaya Traffic</span>
				<br>
				<div class='flex items-center mt-2'>
					<img src='../assets/malaysia.png' alt='' class='h-5'>
					<span class='ml-2 text-lg font-light'>Malaysia</span>
				</div>
			</div>
			<div class=''>
				<div class='flex flex-col items-end'>
					<div class='bg-red-600 hover:bg-red-700 select-none flex px-3 py-1 w-fit items-center justify-between rounded-sm'>
						<div class='w-4 h-4 bg-white rounded-full  mr-2'></div>
						<span class='text-white font-bold text-xl'>LIVE TRAFFIC</span>
					</div>
					<span class='text-black font-light text-xs mt-1'>Last Updated: Today, {{currentTime.format('HH')}}:00</span>
				</div>
			</div>
		</div>
		<div class='grid grid-cols-3 gap-x-4 mt-12'>
			<div>
				<div class="flex items-center">
					<span class='font-light text-lg'>Global Index (Last Hour)</span>
					<div v-tippy="'Global index as an overview metrics... '" class="ml-2">
						<svg  xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 stroke-blue-600 hover:stroke-blue-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
							<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
						</svg>
					</div>
				</div>	
				<div class='flex items-center mt-6'>
					<img src='../assets/global.png' class='h-20 w-20' alt='Smart Selangor Logo'/>
					<span class='font-bold text-7xl ml-6'>91</span>
				</div>
				<div class='mt-4 font-semibold text-lg'>
					<span class='text-emerald-600'>&#8593; 3</span>
					<span> from the past hour</span>
				</div>
			</div>
			<div>
				<span class='font-light text-lg'>Congestion Level (Last Hour)</span>
				<div class='flex items-center mt-6'>
					<img src='../assets/traffic-jam.png' class='h-20 w-20' alt='Smart Selangor Logo'/>
					<span class='font-bold text-7xl ml-6'>87%</span>
				</div>
				<div class='mt-4 font-semibold text-lg'>
					<span class='text-red-600'>&#8593; 6%</span>
					<span> from the past hour</span>
				</div>
			</div>
			<div>
				<span class='font-light text-lg'>Traffic Jams Length (Last Hour)</span>
				<div class='flex items-center mt-6'>
					<img src='../assets/road.png' class='h-20 w-20' alt='Smart Selangor Logo'/>
					<span class='font-bold text-5xl ml-6'>9.7 KM</span>
				</div>
				<div class='mt-4 font-semibold text-lg'>
					<!-- <span class='text-red-600'>&#8593; 6%</span> -->
					<span>out of</span>
					<span > 15.7 KM</span>
					<span> of total road lengths</span>
				</div>
			</div>
		</div>
		<span class='mt-8 font-light text-xl'>Traffic Flow</span>
		<div class='mt-2 grow'>
			<div class='h-full mr-[-0.5rem]'>
				<!-- <span class='ml-12 mt-8 font-light text-xl'>City's Traffic Flow</span> -->
				<FrontCongestionChart/>
			</div>
		</div>
		<div class='p-4 mt-4 bg-green-100'>
			<span>Today's trafic flow shows an improvement compared to the 
				average traffic flow in 2021
			</span>
		</div>
		<div class='mt-2 flex'>
			<!-- <button class='ml-auto'>
				EXPAND
			</button> -->
		</div>

	</div>
</template>

<script>
import FrontCongestionChart from '@/charts/FrontCongestionChart.vue';
import { onMounted, ref } from 'vue';
import dayjs from 'dayjs';

export default {
	name: 'LiveTraffic',
	components: {
		FrontCongestionChart
	},
	setup(){
		const currentTime = ref(dayjs())
		
		// onMounted(() => {
		// 	setInterval(() =>{
		// 		currentTime.value = dayjs()
		// 	}, 1000)
		// })

		return { currentTime }
	}
}
</script>