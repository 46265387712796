<template>
	<div class='mx-4 flex flex-cols-reverse'>
		<div class='grid grid-cols-3 gap-x-2 gap-y-3'>
			<div class='flex items-center text-sm font-thin'>
				<div class='w-4 h-4 bg-[#018002] mr-2'></div>
				Free Flow
			</div>
			<div class='flex items-center text-sm font-thin'>
				<div class='w-4 h-4 bg-[#fefe00] mr-2'></div>
				Light Traffic
			</div>
			<div class='flex items-center text-sm font-thin'>
				<div class='w-4 h-4 bg-[#ffa500] mr-2'></div>
				Moderate Traffic
			</div>
			<div class='flex items-center text-sm font-thin'>
				<div class='w-4 h-4 bg-[#ff0000] mr-2'></div>
				Heavy Traffic
			</div>
			<div class='flex items-center text-sm font-thin'>
				<div class='w-4 h-4 bg-[#a60f0f] mr-2'></div>
				Bumper to Bumper
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CongestionChartLegend',
}
</script>

<style lang='scss'>

</style>