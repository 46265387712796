import { createStore } from 'vuex'
import dayjs from 'dayjs'


export default createStore({
	state: {
		// routeList: {
		// 	1: 'R1: Persiaran Kewajipan (Summit Subang USJ) -> Persiaran Kewajipan (Shell USJ 7)',
		// 	2: 'R2: Persiaran Kewajipan (Summit Subang USJ) -> Persiaran Kewajipan (Petronas USJ 20)',
		// 	3: 'R3: Persiaran Kewajipan (Shell USJ 7) -> Persiaran Kewajipan (Petronas USJ 20)',
		// 	4: 'R4: Persiaran Kewajipan (Petronas USJ 20) -> Persiaran Kewajipan (Summit Subang USJ)',
		// 	5: 'R5: Persiaran Kewajipan (Shell USJ 7) -> Persiaran Perpaduan (Taipan USJ)',
		// 	6: 'R6: Persiaran Perpaduan (Taipan USJ) -> Persiaran Bakti (MBSJ)',
		// 	7: 'R7: Persiaran Bakti (MBSJ) -> Persiaran Kewajipan (Summit Subang USJ)',
		// 	8: 'R8: Persiaran Bakti (MBSJ) -> Persiaran Kewajipan (Shell USJ 7)',
		// 	9: 'R9: Persiaran Kewajipan (Petronas USJ 20) -> Persiaran Perpaduan (Taipan USJ)',
		// },

		// routeList: {
		// 	1: 'R1: Summit Subang USJ -> Shell USJ 7',
		// 	2: 'R2: Summit Subang USJ -> Petronas USJ 20',
		// 	3: 'R3: Shell USJ 7 -> Petronas USJ 20',
		// 	4: 'R4: Petronas USJ 20 -> Summit Subang USJ',
		// 	5: 'R5: Shell USJ 7 -> Taipan USJ',
		// 	6: 'R6: Taipan USJ -> MBSJ',
		// 	7: 'R7: MBSJ -> Summit Subang USJ',
		// 	8: 'R8: MBSJ -> Shell USJ 7',
		// 	9: 'R9: Petronas USJ 20 -> Taipan USJ',
		// },

		// routeList: [
		// 	{id: 1, name: 'R1: Summit Subang USJ -> Shell USJ 7'},
		// 	{id: 2, name: 'R2: Summit Subang USJ -> Petronas USJ 20'},
		// 	{id: 3, name: 'R3: Shell USJ 7 -> Petronas USJ 20'},
		// 	{id: 4, name: 'R4: Petronas USJ 20 -> Summit Subang USJ'},
		// 	{id: 5, name: 'R5: Shell USJ 7 -> Taipan USJ'},
		// 	{id: 6, name: 'R6: Taipan USJ -> MBSJ'},
		// 	{id: 7, name: 'R7: MBSJ -> Summit Subang USJ'},
		// 	{id: 8, name: 'R8: MBSJ -> Shell USJ 7'},
		// 	{id: 9, name: 'R9: Petronas USJ 20 -> Taipan USJ'},
		// ],

		routeList: [
			{
				id: 1, 
				name: 'Persiaran Bakti',
				path: [ 
					{lat: 3.0567419298419694, lng: 101.58501332441608},
					{lat: 3.0539563977608757, lng: 101.58509915509983},
					{lat: 3.050999440415345, lng: 101.58297484567689},
					{lat: 3.0394929419056997, lng: 101.58284609964532},
				]
			},
			{
				id: 2, 
				name: 'Persiaran Ikhlas',
				path: [
					{lat: 3.046840400629537, lng: 101.5828761018983},
					{lat: 3.046926109983997, lng: 101.58553685309472},
					{lat: 3.047097528672433, lng: 101.58635224459042},
					{lat: 3.048126040229737, lng: 101.58738221279549},
				]
			},
			{
				id: 3, 
				name: 'Persiaran Murni',
				path: [
					{lat: 3.05744902537476, lng: 101.5782219714339},
					{lat: 3.056827638484939, lng: 101.57920902430283},
					{lat: 3.0568062113443903, lng: 101.59191196557262},
				]
			},
			{
				id: 4, 
				name: 'Persiaran Mulia',
				path: [
					{lat: 3.0431141808758033, lng: 101.58814614506828},
					{lat: 3.0432856001709494, lng: 101.6021365465205},
				]
			},
			{
				id: 5, 
				name: 'Persiaran Kewajipan',
				path: [
					{lat: 3.0623773352536534, lng: 101.59290402370905},
					{lat: 3.0610917127179436, lng: 101.59292548138},
					{lat: 3.0586918798575944, lng: 101.59191697084587},
					{lat: 3.0500138678915953, lng: 101.59180968245337}, 
					{lat: 3.0458783955414765, lng: 101.58852665870884},
					{lat: 3.0449141639721655, lng: 101.58811896296099},
					{lat: 3.032652153439657, lng: 101.58799200572648},
					{lat: 3.031859331142788, lng: 101.58756285222269},
					{lat: 3.031366495380739, lng: 101.58702641044921},
					{lat: 3.0309593700166766, lng: 101.58578186553473},
					{lat: 3.0308522317375295, lng: 101.58417254021433},
					{lat: 3.0300780958135536, lng: 101.58218659337697},
					{lat: 3.0283451929490726, lng: 101.57979517523417}, 
				]
			},
			{
				id: 6, 
				name: 'Persiaran Perpaduan',
				path: [
					{lat: 3.0505280406021367, lng: 101.57664483249017 },
					{lat: 3.050442331560974, lng: 101.58398335620397},
					{lat: 3.0502709134055097, lng: 101.58475583235777},
					{lat: 3.046713980514914, lng: 101.5891117395584}, 
				]
			},
			{	   
				id: 7, 
				name: 'Persiaran Setia',
				path: [
					{lat: 3.062145193481076, lng: 101.56709137804681},
					{lat: 3.0588454251147463, lng: 101.57160821791119},
					{lat: 3.054838549881261, lng: 101.57141509885739},
					{lat: 3.0539600351398732, lng: 101.57087865708391}, 
					{lat: 3.0539386078883983, lng: 101.57087865704143},
					{lat: 3.0493746050196346, lng: 101.57010618088763},
					{lat: 3.0480889668406204, lng: 101.56924787405714},
					{lat: 3.0479818302310613, lng:  101.56922641642399}, 
					{lat: 3.0461819341196503, lng: 101.56965556984275},
					{lat: 3.0450248564590088, lng: 101.57124343749226},
					{lat: 3.044896292197747, lng: 101.5756637177057}
				]
			}, 
			{
				id: 8, 
				name: 'Persiaran Subang',
				path: [
					{lat: 3.0338182331029264, lng: 101.58825215147542},
					{lat: 3.0334753914763746, lng: 101.59065541065837},
					{lat: 3.034203929809837, lng: 101.59413155345551},
					{lat: 3.057881157754424, lng: 101.59417446886465}, 
				]
			},
			{
				id: 9, 
				name: 'Persiaran Tujuan',
				path: [
					{lat: 3.072438472526596, lng: 101.57876516287217},
					{lat: 3.070210080112463, lng: 101.58088947229514},
					{lat: 3.069524419972743, lng: 101.58108259133358},
					{lat: 3.067617425395746, lng: 101.58013845381225 }, 
					{lat: 3.0606750791934556, lng:  101.5802886575171},
					{lat: 3.05546829003709, lng: 101.57679105698526},
					{lat: 3.054846902000374, lng: 101.57657648027586},
					{lat: 3.0478828828289783, lng: 101.57653122313448}, 
					{lat: 3.042418902642457, lng: 101.57522230519892},
					{lat: 3.0414118130834575, lng: 101.57528667821174},
					{lat: 3.0410689738700305, lng: 101.57550125492112},
					{lat: 3.040190447845129, lng: 101.57657413866752}, 
					{lat: 3.039419058570965, lng: 101.57955675502009},
					{lat: 3.039376203596073, lng: 101.588032535041}, 
				]
			},
		],

		routeId: 5,
		// startDate: dayjs().format('YYYY-MM-DD'),
		// endDate: dayjs().format('YYYY-MM-DD'),
		// set default start time frame
		startDate: dayjs('2022-10-01').format('YYYY-MM-DD'),
		endDate: dayjs('2023-01-01').format('YYYY-MM-DD'),
		detailsData: {},
		matrixDetailsData: {data: {
			vehicle_count_max: 100
		}},
		// [congestions, control panel, vehicles detected]
		headerActive: [false, false, false, false],
		timeframeLabel: 'Today',
		rangeActive: [false, false, false, false],
		
	},

	getters: {
	},

	mutations: {
		setRouteId(state, routeId){
			state.routeId = routeId
		},

		setStartDate(state, date){
			state.startDate = date
		},

		setEndDate(state, date){
			state.endDate = date
		},

		setDetailsData(state, data){
			state.detailsData = data
		},

		setMatrixDetailsData(state, data){
			state.matrixDetailsData = data
		},

		setHeaderActive(state, data){
			state.headerActive = data
		},

		setTimeframeLabel(state, data){
			state.timeframeLabel = data
		},

		setRangeActive(state, data){
			state.rangeActive = data
		},


	},

	actions: {
	},
	
	modules: {
	}
})
