<template>
	<canvas id='carbonVehicleTypeChart' class=''></canvas>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'

import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
	name: 'CarbonVehicleTypeChart',
	components: {
		
	},
	setup(){
		const store = useStore()
		const data = [123, 324, 213, 342, 423, 323]

		let chart = null
		function renderChart(){
			const ctxChart = document.getElementById('carbonVehicleTypeChart').getContext('2d')
			const chart = new Chart(ctxChart, {
				type: 'doughnut',
				
				// backgroundColor: gradient,
				data: {
					labels: ['Light Vehicle', 'MPV/SUV', 'Motorcycle', 'Truck', 'Bus', 'Trailer'],
					datasets: [{
						data: data,
						backgroundColor: [
							'rgba(255, 99, 132, 0.4)',
							'rgba(54, 162, 235, 0.4)',
							'rgba(255, 206, 86, 0.4)',
							'rgba(75, 192, 192, 0.4)',
							'rgba(153, 102, 255, 0.4)',
							'rgba(255, 159, 64, 0.4)'
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 159, 64, 1)'
						],
						fill: true,
						borderWidth: 2,
						barPercentage: 1.0,
						categoryPercentage: 1.0,
					},
					]
				},
				options: {
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
					},
					plugins: {
						legend: {
							position: 'right'
						},
						datalabels: {
							color: '#292929',
							formatter: function(value, context) {
								return parseInt((value/data.reduce((a, b) => a + b, 0))*1000)/10+"%"
							}
						},
						tooltip: {
							displayColors: true,
							callbacks: {
								title(context){
									return context[0].label
								},
								label(context){
									return ` ${context.raw} KG (${parseInt((context.raw/data.reduce((a, b) => a + b, 0))*1000)/10}%)`
								}
							}
						}
						// title:{
						// 	display: true,
						// 	text: 'Vehicle CO2 Emission',
						// 	padding: 4
						// },
					},
					responsive: true,
				},
				plugins: [ChartDataLabels],
			})
			return chart
		}

		onMounted(() => {
			chart = renderChart()
		})
	}
}
</script>

<style lang='scss'>

</style>