<template>
	<div class='bg-white rounded-sm px-4 pt-4 pb-2 flex flex-col h-fit'>
		<div class='flex justify-between items-center mb-4'>
			<span class='font-bold text-2xl '>Traffic Congestion</span>
			<div class="flex">
				<div class='flex items-center'>
					<label for='bound-toggle' class='relative cursor-pointer h-10 w-60 flex bg-slate-100 rounded-md hover:bg-slate-200 '>
						<input v-model='isSouthBound' type='checkbox' value='' id='bound-toggle' class='sr-only peer' checked>
						<span class='text-center m-auto select-none'>
							{{ isSouthBound ? 'South Bound' : 'North Bound'}}
						</span>
						<div class='h-8 w-8 bg-white rounded-md absolute right-1 top-1 peer-checked:-translate-x-[12.5rem] shadow-md transition-all'></div>
					</label>
				</div>
				<div class='flex items-center ml-4'>
					<label for='checked-toggle' class='relative cursor-pointer h-10 w-60 flex bg-slate-100 rounded-md hover:bg-slate-200 '>
						<input v-model='isVehicleCount' type='checkbox' value='' id='checked-toggle' class='sr-only peer' checked>
						<span class='text-center m-auto select-none'>
							{{ isVehicleCount ? 'Vehicle Count' : 'Congestion Level'}}
						</span>
						<div class='h-8 w-8 bg-white rounded-md absolute right-1 top-1 peer-checked:-translate-x-[12.5rem] shadow-md transition-all'></div>
					</label>
				</div>
			</div>
		</div>
		<div class=''>
			<div class="grid grid-cols-3 gap-x-2">
				<div class='col-span-2'>
					<div class='mb-2'>	
						<div v-if='isVehicleCount' class='flex flex-col-reverse h-16 pb-1'>
							<div class='ml-auto flex items-center'>	
								<div class='w-4 h-4 bg-[#d90b0b]'></div>
								<span class='ml-2 text-sm'>Best Time to Avoid</span>
							</div>
						</div>
						<div v-else class="">
							<div class=''>
								<CongestionChartLegend class='h-16'/>
							</div>
							<div class="mt-2 flex justify-end items-center">		
								<span class="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">Show Percentage</span>
								<label for="default-toggle" class="inline-flex relative items-center cursor-pointer">
									<input v-model='showPercentage' type="checkbox" value="" id="default-toggle" class="sr-only peer">
									<div class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="grid grid-cols-3 gap-x-2">
				<div class="col-span-2">
					<VehicleCountChartBig :isVehicleCount='isVehicleCount' :showPercentage='showPercentage' :isSouthBound="isSouthBound" class=''/>
				</div>
				<div class='pl-2 mt-3'>
					<div class='grid grid-rows-3 h-full'>
						<!-- <div class='flex flex-col pb-6'>	
							<span class='font-extralight'>Best Time to Travel</span>
							<span class='font-bold text-2xl mt-1'>6 AM to 8 AM</span>
							<div class="grow mt-2">
								<div class="h-36 w-36">
									<CongestionHighlightTimeChart chartId="bestTimeToTravel" :pos="[21, 22, 23, 24, 25, 26, 27]" color="rgba(0,255,0,1)" class="h-full w-full"/>
								</div>
							</div>
						</div> -->
						<div class='flex flex-col pb-6'>	
							<span class='font-extralight'>Best Time to Avoid</span>
							<span class='font-bold text-2xl mt-1'>{{isSouthBound ? '5 AM to 7 AM':'5 PM to 7 PM '}}</span>
							<div class="grow mt-2">
								<div class="h-36 w-36">
									<CongestionHighlightTimeChart chartId="bestTimeToAvoid" :pos="isSouthBound ? [29, 30, 31, 32, 33] : [57, 58, 59, 60, 61]" color="rgba(255,0,0,1)" class="h-full w-full"/>
								</div>
							</div>
						</div>
						<div class='flex flex-col pb-5 mt-2'>	
							<span class='font-extralight'>Best Time to Avoid for The Week</span>
							<span class='font-bold text-2xl mt-1'>{{isSouthBound ? 'Monday, 5 AM to 7 AM':'Friday, 5 PM to 7 PM'}} </span>
							<div class="grow mt-2">
								<div class="h-36 w-36">
									<CongestionHighlightTimeChart chartId="bestTimeToAvoidForTheWeek" :pos="isSouthBound ? [29] : [61]" color="rgba(255,0,0,1)" class="h-full w-full"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

import VehicleCountChartBig from '@/charts/VehicleCountChartBig.vue';
import CongestionChartLegend from '@/charts/CongestionChartLegend.vue'
import CongestionHighlightTimeChart from '@/charts/CongestionHighlightTimeChart.vue';

export default {
	name: 'TrafficCongestion',
	components: {
		VehicleCountChartBig,
		CongestionChartLegend,
		CongestionHighlightTimeChart
	},
	setup(){
		const isVehicleCount = ref(true)
		const showPercentage = ref(false)
		const isSouthBound = ref(true)

		return { isVehicleCount, showPercentage, isSouthBound }
	}
}
</script>